import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper, { PaperProps } from '../components/paper/Paper';
import { GetAllUserCollections, GetTrendingData } from '../services/DigestService';
import { mockDigestData } from '../services/mockServiceData';
import { sanitizeColour, sanitizeDate, sanitizeRelevance } from '../utils/apiUtils';
import FilterBar from '../components/filterbar/FilterBar';
import FilterBarShowParam from '../components/filterbar/FilterBarShowParam';
import FilterBarSortOrder from '../components/filterbar/FilterBarSortOrder';
import FilterBarSortParam from '../components/filterbar/FilterBarSortParam';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import { useNavigate } from 'react-router-dom';
import CompactPaper from '../components/paper/CompactPaper';

function sortPapersByTrending(
  papers: PaperProps[],
  sortBy: string,
  sortOrder: string,
  dateFilter: string,
): PaperProps[] {
  let sortedPapers = [...papers];

  switch (sortBy) {
    case 'date':
      sortedPapers.sort(
        (a, b) =>
          new Date(a.submittedDate || '') > new Date(b.submittedDate || '')
            ? 1
            : -1
      );
      break;
    case 'relevance':
      sortedPapers.sort((a, b) => (b.relevance || 0) - (a.relevance || 0));
      break;
    case 'hype':
      sortedPapers.sort((a, b) => (b.relevance || 0) + (b.read ? 1 : 0) - (a.relevance || 0) - (a.read ? 1 : 0));
      break;
    default:
      break;
  }

  if (sortOrder === 'desc') {
    sortedPapers.reverse();
  }

  switch (dateFilter) {
    case '7':
      sortedPapers = sortedPapers.filter((paper) => {
        const date = new Date(paper.submittedDate || '');
        const cutoff = new Date();
        cutoff.setDate(cutoff.getDate() - 7);
        return date >= cutoff;
      });
      break;
    case '14':
      sortedPapers = sortedPapers.filter((paper) => {
        const date = new Date(paper.submittedDate || '');
        const cutoff = new Date();
        cutoff.setDate(cutoff.getDate() - 14);
        return date >= cutoff;
      });
      break;
    case 'all':
    default:
      break;
  }

  return sortedPapers;
}


export default function TrendingPage() {

  const navigate = useNavigate();
  const [category, setCategory] = useState('All');
  const [categories, setCategoriesList] = useState([['All', 'All']]);
  const [sortParam, setSortParam] = useState('hype');
  const [sortOrder, setSortOrder] = useState('0');
  const [sortPeriod, setSortPeriod] = useState('7');
  const [paging, setPaging] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [trendingFeed, setTrendingFeed] = useState<PaperProps[]>([]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      return;
    }

    // build digest data
    setCategoriesList(response.categories)

    const digest: PaperProps[] = responseSetPaperProps(response);
    setTrendingFeed(digest);
    setHasNextPage(response.has_next);
  };

  const handleSortParamChange = (event: SelectChangeEvent) => {
    setSortParam(event.target.value);
    setPaging(1);

    const trendingData = GetTrendingData(event.target.value, category, sortOrder, sortPeriod);
    trendingData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };


  const handleSortOrderChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value);
    setPaging(1);

    const trendingData = GetTrendingData(sortParam, category, event.target.value, sortPeriod);
    trendingData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };


  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
    setPaging(1);

    const trendingData = GetTrendingData(sortParam, event.target.value, sortOrder, sortPeriod);
    trendingData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };


  const handleSortPeriodChange = (event: SelectChangeEvent) => {
    setSortPeriod(event.target.value);
    setPaging(1);

    const trendingData = GetTrendingData(sortParam, category, sortOrder, event.target.value);
    trendingData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };


  const fetchNextPage = () => {
    console.log('fetching next');

    const trendingData = GetTrendingData(sortParam, category, sortOrder, sortPeriod, paging);
    trendingData.then(response => {
      if (response.success) {
        let digest = responseSetPaperProps(response);
        console.log(digest);
        setTrendingFeed(trendingFeed.concat(digest));
        setHasNextPage(response.has_next);
        setPaging(paging + 1);
      } else {
        if (response.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    console.log('done next');
  };


  // load initial digest data
  useEffect(() => {
    const relevanceData = GetTrendingData();
    const featureTogglesData = GetFeatureToggles();
    const allUserCollectionsRow = GetAllUserCollections();

    relevanceData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });

    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });
  }, []);


  return (
    <BoxDefaultContent fullWidth>
      <FilterBar>
        <FilterBarSortParam
          handleChange={handleCategoryChange}
          label='Category'
          optionList={categories}
          value={category}
        />
        <FilterBarSortParam
          handleChange={handleSortParamChange}
          label='Sort option'
          optionList={[
            ['publication_date', 'Date'],
            ['ranking_score', 'Relevance'],
            ['hype', 'Reads'],
          ]}
          value={sortParam}
        />
        <FilterBarSortOrder
          handleChange={handleSortOrderChange}
          label='Sort order'
          optionList={[
            ['1', 'Ascending'],
            ['0', 'Descending'],
          ]}
          value={sortOrder}
        />
        <FilterBarSortOrder
          handleChange={handleSortPeriodChange}
          label='Sort period'
          optionList={[
            ['7', '7 days'],
            ['14', '14 days'],
            ['all', 'All'],
          ]}
          value={sortPeriod}
        />

      </FilterBar>
      <InfiniteScroll
        dataLength={trendingFeed.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        style={{ overflow: 'visible' }}
        loader={
          <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
            <TripleDotLoadingProgress />
          </Box>
        }
      >
        {trendingFeed.map(paper => (
          <CompactPaper
            {...paper}
            key={paper.title}
            featureToggles={featureToggles}
            all_user_collections={allUserCollections}
          />
        ))}
      </InfiniteScroll>

    </BoxDefaultContent>
  )
}
