import { useEffect, useRef, useState } from 'react';
import { Alert, AlertTitle, Box, Button, IconButton, Link, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '../components/paper/Paper';
import dayjs, { Dayjs } from 'dayjs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TrendingBar, { TrendingPaperInfo } from '../components/TrendingBar';
import { isWeekend } from '../utils/dateUtils';
import { GetDigestData, GetDigestDateRangeData, GetQuestionnaireData, SetUserSeenWelcomeTutorial } from '../services/DigestService';
import { sanitizeRelevance } from '../utils/apiUtils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BreakpointUtils from '../utils/BreakpointUtils';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import { dateAfterLastDigest } from '../utils/dateUtils';
import { isAndroid } from 'react-device-detect';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import '../styles/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GetCatchUpData } from "../services/AuthService";
import HomeIcon from '@mui/icons-material/Home';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import { GetAllUserCollections } from '../services/DigestService';
import CloseIcon from '@mui/icons-material/Close';
import 'dayjs/locale/en-gb';
import SIVideoPlayer from '../components/SIVideoPlayer';
import ls from 'localstorage-slim';
import { convertRelevance } from '../utils/relevanceUtils';
import CompactPaper from '../components/paper/CompactPaper';
import React from 'react';
dayjs.locale('en-gb');

// arbitrary offset value to allow header + nav menus to be above the paper we are scrolling to
export const SCROLL_Y_OFFSET = -150;

type SingleDate = Date | null;
type DateRange = SingleDate | [SingleDate, SingleDate];

const PDFViewerContainerStyles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '100%',
  bgcolor: 'background.paper',
  transformOrigin: '0 0',
  boxShadow: 24,
};

const TutorialModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  maxWidth: '1250px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pb: 1,
}

export const scrollTo = (elementId: string, offset: number = SCROLL_Y_OFFSET, behaviour: ScrollBehavior = 'instant') => {
  const element = document.getElementById(elementId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top: y, behavior: behaviour });
  }
}

const isBoundary = (paperId: number, firstPaperIdBelowBoundary: number): boolean => {
  return paperId == firstPaperIdBelowBoundary;
};

export default function DigestPage() {
  const navigate = useNavigate();
  // make sure the date showing my default is not a weekend date
  let landingDate = dayjs();
  const onMobile = BreakpointUtils.lessThanMedium();
  while (isWeekend(landingDate)) {
    landingDate = landingDate.subtract(1, 'day');
  }
  const [date, setDate] = useState<Dayjs | undefined>(landingDate);
  const [digestFeed, setDigestFeed] = useState<PaperProps[]>([]);
  const [teaserFiguresShown, setTeaserFiguresShown] = useState<boolean[]>([]);
  const [trendingPapersInfo, setTrendingPapers] = useState<TrendingPaperInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState<boolean>(false);
  const [latestDigestDate, setLatestDigestDate] = useState<Dayjs>(dayjs().startOf('year'));
  const [firstPaperIdBelowBoundary, setDecisionBoundary] = useState<number>()
  const [PDFViewerURL, setPDFViewerURL] = useState<string>('');
  const [confNotificationText, setConfNotificationText] = useState<string>('');
  const [adminProceedingsMsg, setAdminProceedingsMsg] = useState<string>('');
  const [displayNextDate, setDisplayNextDate] = useState<boolean>(false);
  const pdfReaderRef = useRef(null);
  const [totalPapers, setTotalPapers] = useState<number>(0);
  const [digestPaperCount, setDigestPaperCount] = useState<number>(0);
  const [catchUpOpen, setCatchUpOpen] = useState<boolean>(false);
  const [catchUpRange, setCatchUpRange] = useState<number>(1);
  const [nextDate, setNextDate] = useState<Dayjs | undefined>();
  const [prevDate, setPrevDate] = useState<Dayjs | undefined>();
  const [paging, setPaging] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>([new Date(), new Date()]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);
  const [isCustomDigestRange, setIsCustomDigestRange] = useState<boolean>(false);
  const [noWeekdaysSelected, setNoWeekdaysSelected] = useState<boolean>(false);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      return;
    }
    setPDFViewerURL(response.pdf_viewer_url);
    if (!response.no_weekdays_selected) {
      setDate(dayjs(response.current_digest_date, "YYYY-MM-DD"));
      setNextDate(dayjs(response.next_date, "YYYY-MM-DD"));
      setPrevDate(dayjs(response.prev_date, "YYYY-MM-DD"));
      setDateRange([new Date(response.from_date), new Date(response.to_date)]);
    }
    
    setIsCustomDigestRange(response.custom_digest_range);
    setNoWeekdaysSelected(response.no_weekdays_selected);
    let digest = responseSetPaperProps(response);
    setDigestFeed(digest);
    const teaserFiguresShown = new Array(digest.length).fill(false);
    setTeaserFiguresShown(teaserFiguresShown);
    // build  trending bar data
    const trendingPapers: TrendingPaperInfo[] = [];
    response.trending.map((trending_paper: any) => {
      const trendingPaper: TrendingPaperInfo = {
        paperId: trending_paper.paper_id,
        paperTitle: trending_paper.short_title,
        author: trending_paper.author,
        total_reads: trending_paper.total_read,
        likes: trending_paper.total_likes,
        relevance: sanitizeRelevance(convertRelevance(trending_paper.ranking_score)),
        colour: trending_paper.color,
        paperLink: trending_paper.url,
        rating: trending_paper.rating,
        read: trending_paper.read,
        useScholarInboxPdfServer: response.use_scholar_inbox_pdf_server,
        is_cached: trending_paper.is_cached,
        is_cached_filename: trending_paper.is_cached_filename,
        digestDate: trending_paper.digest_date,
      }
      trendingPapers.push(trendingPaper);
    })
    setTrendingPapers(trendingPapers);

    // check if this is the latest digest; set dates

    // set id of the first paper below decision boundary
    setDecisionBoundary(response.first_paper_id_below_decision_boundary)
    setTotalPapers(response.total_papers);
    setDigestPaperCount(response.digest_papers);
    // api says no next button = latest digest
    const digestDate = dayjs(response.current_digest_date, "YYYY-MM-DD");
    if (!response.display_next_date) {
      if (dateAfterLastDigest(digestDate, latestDigestDate)) {
        setLatestDigestDate(digestDate);
      }
    }

    setDisplayNextDate(response.display_next_date);
    // set conference notification text and admin proceedings message
    setConfNotificationText(response.conf_notification_text);
    setAdminProceedingsMsg(response.admin_proceedings_msg);

    // set paging info
    setHasNextPage(response.has_more_papers_in_digest);

    setLoading(false);
  };

  const handlePrevDayClick = () => {
    setLoading(true);
    setDigestFeed([]);
    // if it's custom digest range, we need to get the previous digest range
    // we do so by taking constant steps back from the current date range
    // to exit the state - click Home or X button
    if (isCustomDigestRange) {
      // set up new date range
      const dateDifference = dateDiffInDays(dateRange[0], dateRange[1]);

      const newStartDate = new Date(dateRange[0]);
      const newEndDate = new Date(dateRange[0]);
      newEndDate.setDate(dateRange[0].getDate() - 1);
      newStartDate.setDate(newEndDate.getDate() - dateDifference);

      const newDateRange: DateRange = [newStartDate, newEndDate];
      setDateRange(newDateRange);
      let stringStartDate = dayjs(newDateRange[0]).format('MM-DD-YYYY').toLocaleString();
      let stringEndDate = dayjs(newDateRange[1]).format('MM-DD-YYYY').toLocaleString();
      GetDigestDateRangeData(stringStartDate, stringEndDate).then(res => {
        if (res.success) {
          handleDataFeedUpdate(res);
          setPaging(1);
          setDate(dayjs(newEndDate));
        } else {
          if (res.next_step === 'bootstrapping') {
            // redirect
            navigate({
              pathname: '/onboarding/registration/',
            });
          }
        }
      });
    } else {
      // make sure the previous day button returns the date with published digest
      setDate(prevDate);
      GetDigestData(prevDate?.format('MM-DD-YYYY')!).then(res => {
        if (res.success) {
          handleDataFeedUpdate(res);
          setPaging(1);
        } else {
          if (res.next_step === 'bootstrapping') {
            // redirect
            navigate({
              pathname: '/onboarding/registration/',
            });
          }
        }
      });
    }
  };


  const handleHomeClick = () => {
    setLoading(true);
    setDigestFeed([]);
    GetDigestData().then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
        setPaging(1);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };


  const handleCatchUp = () => {
    setCatchUpOpen(false);
    setLoading(true);
    setDigestFeed([]);

    var startDate = date.toDate();
    var endDate = date.toDate();
    startDate.setTime(startDate.getTime() - _MS_PER_DAY * (catchUpRange - 1));
    setDateRange([startDate, endDate]);

    let stringStartDate = dayjs(startDate).format('MM-DD-YYYY').toLocaleString();
    let stringEndDate = dayjs(endDate).format('MM-DD-YYYY').toLocaleString();

    GetDigestDateRangeData(stringStartDate, stringEndDate).then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };

  const handleCatchUpClose = () => {
    setCatchUpOpen(false);
  }

  const handleNextDayClick = () => {
    setLoading(true);
    setDigestFeed([]);
    // if it's custom digest range, we need to get the next digest range
    // we do so by taking constant steps back from the current date range
    // to exit the state - click Home or X button
    if (isCustomDigestRange) {
      // set up new date range
      const dateDifference = dateDiffInDays(dateRange[0], dateRange[1]);

      const newStartDate = new Date(dateRange[0]);
      const newEndDate = new Date(dateRange[0]);
      newStartDate.setDate(dateRange[1].getDate() + 1);
      newEndDate.setDate(newStartDate.getDate() + dateDifference);

      if (newStartDate > new Date()) {
        newStartDate.setDate(new Date().getDate());
      }
      if (newEndDate > new Date()) {
        newEndDate.setDate(new Date().getDate());
      }

      const newDateRange: DateRange = [newStartDate, newEndDate];
      setDateRange(newDateRange);
      let stringStartDate = dayjs(newDateRange[0]).format('MM-DD-YYYY').toLocaleString();
      let stringEndDate = dayjs(newDateRange[1]).format('MM-DD-YYYY').toLocaleString();
      GetDigestDateRangeData(stringStartDate, stringEndDate).then(res => {
        if (res.success) {
          handleDataFeedUpdate(res);
          setPaging(1);
          setDate(dayjs(newEndDate));
        } else {
          if (res.next_step === 'bootstrapping') {
            // redirect
            navigate({
              pathname: '/onboarding/registration/',
            });
          }
        }
      });
    } else {
      // make sure the next day button returns the date with published digest
      setDate(nextDate);
      GetDigestData(nextDate?.format('MM-DD-YYYY')!).then(res => {
        if (res.success) {
          handleDataFeedUpdate(res);
          setPaging(1);
        } else {
          if (res.next_step === 'bootstrapping') {
            // redirect
            navigate({
              pathname: '/onboarding/registration/',
            });
          }
        }
      });
    }
    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  const fetchNextDigest = () => {
    if (!hasNextPage) {
      console.log('fetching next triggered but no more papers');
      return;
    }

    console.log('fetching next');

    const digestFromDate = dayjs(dateRange[0]).format('MM-DD-YYYY').toLocaleString();
    const digestToDate = dayjs(dateRange[1]).format('MM-DD-YYYY').toLocaleString();

    const digestData = digestFromDate !== digestToDate
      ? GetDigestData(date.format('MM-DD-YYYY'), null, paging, digestFromDate, digestToDate)
      : GetDigestData(date.format('MM-DD-YYYY'), null, paging);

    digestData.then(response => {
      console.log(response);
      if (response.success) {
        let digest = responseSetPaperProps(response);
        // console.log(digest);
        setDigestFeed(digestFeed.concat(digest));
        setHasNextPage(response.has_more_papers_in_digest);
        setPaging(paging + 1);
      } else {
        if (response.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    console.log('done next');
  };

  // load initial digest data
  useEffect(() => {
    let digestData;
    const catchUpInfo = GetCatchUpData();
    const featureTogglesData = GetFeatureToggles();
    const allUserCollectionsRow = GetAllUserCollections();
    catchUpInfo.then(response => {
      setCatchUpRange(response.catch_up_date_range);
      setCatchUpOpen(response.catch_up_notification);
    })
    // redirected with pdf viewer open
    if (searchParams.get('paper_id')) {
      // open modal with pdf viewer
      setModalOpened(true);
    }

    // check is in query params and date is of the correct format, use it otherwise use today's date for digest
    const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}$/;
    if (searchParams.get('date') && dateRegex.test(searchParams.get('date'))) {
      // let digestDateFrom =  dateRegex.test(searchParams.get('from')) ? searchParams.get('from') : null;
      //let digestDateTo =  dateRegex.test(searchParams.get('to')) ? searchParams.get('to') : null;
      digestData = GetDigestData(searchParams.get('date'), searchParams.get('paper_id'));
      setDate(dayjs(searchParams.get('date'), "MM-DD-YYYY"));
    } else digestData = GetDigestData();
    digestData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
        setTimeout(() => {
          scrollTo(window.location.hash.substring(1), SCROLL_Y_OFFSET);
          // detect scrolling for notifying papers, setting it here after we scroll to the highlighted paper programatically
          setTimeout(() => {
            window.addEventListener('scroll', handleDetectScroll);
          }, 10);
        }, 10);

        // handle 1st login welcome tutorial modal
        console.log(res);
        setTutorialModalOpen(!res.seen_welcome_tutorial);
        if (!res.seen_welcome_tutorial) {
          const req = SetUserSeenWelcomeTutorial();
          req.then(resp => {
            if (resp.success) {
              console.log('updated seen welcome tutorial');
            }
          })
        }
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/bootsrapping/',
          });
        }
      }

    });

    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });
    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });

  }, []);

  const handleModalClose = () => setModalOpened(false);

  const handleTutorialModalClose = () => setTutorialModalOpen(false);

  // remove listener when scroll is detected
  const handleDetectScroll = (event: Event) => {
    setHasScrolled(true);
    window.removeEventListener('scroll', handleDetectScroll);
  }

  // detect when pdf viewer loads and remove focus from it
  const pdfReaderFrameCurrent = pdfReaderRef.current;
  useEffect(() => {
    pdfReaderFrameCurrent?.addEventListener('load', () => {
      pdfReaderRef.current.focus();
    });
    return () => {
      pdfReaderFrameCurrent?.removeEventListener('load', () => pdfReaderRef.current.blur());
    };
  }, [pdfReaderFrameCurrent]);

  const btnWidth = BreakpointUtils.lessThanMedium() ? 50 : 180;
  const btnExtraDisplay = BreakpointUtils.lessThanMedium() ? 'none' : '';
  const labelNext = BreakpointUtils.lessThanMedium() ? '' : 'Next Digest';
  const labelPrev = BreakpointUtils.lessThanMedium() ? '' : 'Previous Digest';

  // Grey out 'next day' button and make it disabled if current date selected >= today
  const nextDayButton = !displayNextDate
    ? <Button color='secondary' style={{ minWidth: btnWidth, height: '29px', marginLeft: '23px' }} variant='outlined' disabled onClick={handleNextDayClick}>{labelNext} <NavigateNextIcon sx={{ mr: -1 }} /></Button>
    : <Button color='secondary' style={{ minWidth: btnWidth, height: '29px', marginLeft: '23px' }} variant='outlined' onClick={handleNextDayClick}>{labelNext} <NavigateNextIcon sx={{ mr: -1 }} /></Button>

  const prevDayButton =
    <Button color='secondary' style={{ minWidth: btnWidth, height: '29px', marginRight: '8px' }} variant='outlined' onClick={handlePrevDayClick}><NavigateBeforeIcon sx={{ ml: -1 }} /> {labelPrev}</Button>;
  const homeButton =
    <Button title='Current digest' color='secondary' style={{ minWidth: 40, display: btnExtraDisplay, marginLeft: '17px', marginRight: '12px', height: '29px' }} variant='outlined' onClick={handleHomeClick}><HomeIcon /></Button>;
  const digestCounts = <Typography style={{ display: btnExtraDisplay, marginLeft: '12px', marginRight: '17px' }}>{digestPaperCount}/{totalPapers}</Typography>

  let decisionBoundary = <Box sx={{ width: '100%', height: '10px', background: '#dc7464', padding: '0 0', marginTop: '1em', marginBottom: '1em', borderRadius: '10px' }}>
    <img
      style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
      src={"image/decision_boundary.png"}
      alt={'Decision Boundary'}
    />
  </Box>;

  // pass digest range and use it to determine the start date of the range
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  function dateDiffInDays(startDate, endDate) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  const handleDateRangeChange = (newDateRange: [Date, Date] | undefined) => {
    setLoading(true);
    if (newDateRange === null) {
      return handleHomeClick();
    }
    setDigestFeed([]);
    if (newDateRange[1] > new Date()) {
      newDateRange[1] = new Date();
    }
    if (newDateRange[0] > new Date()) {
      newDateRange[0] = new Date();
    }

    const dateRange = dateDiffInDays(newDateRange[0], newDateRange[1]) + 1;
    if (dateRange > 31) {
      newDateRange[0].setTime(newDateRange[1].getTime() - _MS_PER_DAY * 30);
    }

    setDateRange(newDateRange);
    let stringStartDate = dayjs(newDateRange[0]).format('MM-DD-YYYY').toLocaleString();
    let stringEndDate = dayjs(newDateRange[1]).format('MM-DD-YYYY').toLocaleString();

    GetDigestDateRangeData(stringStartDate, stringEndDate).then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    setPaging(1);
  };

  const updateShownTeaserFigures = (idx: number) => {
    console.log('updating' + idx);
    let teaserFiguresShown: boolean[] = new Array(digestFeed.length).fill(false);
    teaserFiguresShown[idx] = true;
    setTeaserFiguresShown(teaserFiguresShown);
  }

  return (
    <BoxDefaultContent fullWidth>

      <TrendingBar trendingPapers={trendingPapersInfo} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          mt: '5px',
          mb: trendingPapersInfo.length > 0 ? '8px' : '16px', // more margin if trending bar is hidden
          position: 'sticky',
          top: '60px', background: 'white', zIndex: '1', borderRadius: '8px',
          // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
          boxShadow: '0 0 10px #ccc',
          width: { xs: '100%', md: '800px' }
        }}
      >
        {homeButton}
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1em',
          marginTop: '1em'
        }}>
          {prevDayButton}
          <DateRangePicker value={dateRange} onChange={handleDateRangeChange} format='dd-MM-yyyy' />
          {nextDayButton}
        </Box>
        <Box sx={{
          alignItems: 'right',
          display: 'flex',
          justifyContent: 'right',
        }}>
          {digestCounts}
        </Box>
      </Box>

      {confNotificationText && confNotificationText.length > 0 && (
        <Box sx={{ mt: '16px' }}>
          <Alert severity="info" icon={false}>
            <AlertTitle>New conference proceedings</AlertTitle>
            <div dangerouslySetInnerHTML={{ __html: confNotificationText }}></div>
          </Alert>
        </Box>
      )}
      {adminProceedingsMsg && (
        <Box sx={{ mt: '16px' }}>
          <Alert severity="success" icon={false}>
            <AlertTitle>New proceedings are scraped</AlertTitle>
            {adminProceedingsMsg} Please check the <Link href='settings/proceedings-uploader' sx={{ color: 'black' }}>proceedings uploader</Link>.
          </Alert>
        </Box>
      )}

      {!loading &&
        <InfiniteScroll
          dataLength={digestFeed.length}
          next={fetchNextDigest}
          hasMore={hasNextPage}
          style={{ overflow: 'visible' }}
          loader={
            <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
              <TripleDotLoadingProgress />
            </Box>
          }
          endMessage={
            (digestFeed.length !== 0 || Number(totalPapers) !== 0) && (
              <>
                <Typography variant={onMobile ? 'h5' : 'h4'} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  No more papers to show!
                </Typography>
                <Typography variant={onMobile ? 'body1' : 'h5'} sx={{ display: onMobile ? 'block' : 'flex', justifyContent: 'center', mt: 1, mx: 'auto' }}>
                  Try&nbsp;<Link href='/settings' color='secondary'>changing the classification threshold</Link>&nbsp;or checking the other days.
                </Typography>
              </>
            )
          }
        >
          {digestFeed.map((paper, idx) => (
            <React.Fragment key={paper.title}>
              {isBoundary(paper.paperId, firstPaperIdBelowBoundary) ? decisionBoundary : null}
              <CompactPaper
                {...paper}
                showTeaserFigures={teaserFiguresShown[idx]}
                paperIndex={idx}
                updateTeaserFiguresShown={updateShownTeaserFigures}
                featureToggles={featureToggles}
                all_user_collections={allUserCollections}
                hasScrolled={hasScrolled}
              />
            </React.Fragment>
          ))}
        </InfiniteScroll>
      }

      <Modal
        open={modalOpened}
        onClose={handleModalClose}
      >
        <Box sx={PDFViewerContainerStyles}>
          {!isAndroid && PDFViewerURL !== '' && (
            <object ref={pdfReaderRef} id="pdf-viewer" data={PDFViewerURL + '#view=FitH'} type="application/pdf" width="100%" height="100%">
              <p>PDF viewer failed to load or the PDF document doesn't exist. Here is a link to the PDF:
                <a href="{PDFViewerURL}">"{PDFViewerURL}"</a>
              </p>
            </object>
          )}
          {isAndroid && PDFViewerURL !== '' && (
            // <a href={PDFViewerURL} download/>
            <iframe src={'https://drive.google.com/viewerng/viewer?embedded=true&url=' + PDFViewerURL} width='100%' height='100%'></iframe>
          )}
        </Box>
      </Modal>

      <Modal
        open={tutorialModalOpen}
        onClose={handleTutorialModalClose}
        disableAutoFocus={true}
      >
        <Box sx={TutorialModalStyle}>
          <IconButton
            sx={{
              position: 'absolute',
              zIndex: 2,
              top: '10px',
              right: '10px',
              cursor: 'pointer',
            }}
            onClick={handleTutorialModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', }}>
            <SIVideoPlayer video_name='scholar_inbox_main_tutorial.mp4' />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant='body1' sx={{ ml: 1, fontSize: '1.2rem' }}><b>Scholar Inbox at a Glance:</b> Learn how to use the Scholar Inbox digest view</Typography>
              <Typography variant='body2' sx={{ mr: 1, fontSize: '1.2rem', color: '#666666' }}><Link href='/tutorials' color='#666666'>More Tutorials</Link></Typography>
            </Box>
          </Box>
        </Box>
      </Modal>

      {!loading && !noWeekdaysSelected && digestFeed.length === 0 && Number(totalPapers) === 0 && (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <Typography variant={onMobile ? 'h5' : 'h4'} sx={{ mt: 4 }}>
            There is no digest for this date.
          </Typography>
          <img src='/image/no_digest.jpg' width={onMobile ? '80%' : '800px'} style={{ marginTop: '-20px', zIndex: '-1' }} />
          <Typography variant={onMobile ? 'body1' : 'h5'} sx={{ mt: 1 }}>
            Note that occasionally arXiv defers announcements due to the holidays, for more see&nbsp;<Link target='_blank' href='https://blog.arxiv.org/' color='secondary'>ArXiv blog</Link>&nbsp;
          </Typography>
        </Box>
      </>)
      }

      {noWeekdaysSelected && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
          <Typography variant={onMobile ? 'h5' : 'h4'} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            No digest to show as you have not selected any digest days.
          </Typography>
          <Typography variant={onMobile ? 'body1' : 'h5'} sx={{ display: onMobile ? 'block' : 'flex', justifyContent: 'center', mt: 1, mx: 'auto' }}>
            <Link href='/settings' color='secondary'>Select some digest days</Link>&nbsp;to see the digest.
          </Typography>
        </Box>
      )}

      {loading && (
        <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 10 }}>
          <TripleDotLoadingProgress />
        </Box>
      )}

      <Dialog
        open={catchUpOpen}
        onClose={handleCatchUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Welcome back to Scholar-Inbox!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have missed few digest. Do you want to catch up on the most relevant papers?
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button style={{ 'color': "black" }} onClick={handleCatchUpClose} >No, continue to digest</Button>
          <Button style={{ 'color': "black" }} onClick={handleCatchUp} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </BoxDefaultContent>
  )
}
