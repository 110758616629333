import { Autocomplete, Box, Paper as MuiPaper, Chip, Grid, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, Switch, debounce, Collapse, } from '@mui/material';
import React, { useEffect, useState, MouseEvent, KeyboardEvent, useMemo } from 'react';
import Paper, { PaperProps } from '../components/paper/Paper';
import { CollapseCollectionTab, ExpandCollectionTab, GetAllUserCollections, GetCollectionsData, GetCollectionsSimilarPapers, GetUserExpandedCollections } from '../services/DigestService';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import { mockDigestData } from '../services/mockServiceData';
// import { sanitizeDate, sanitizeRelevance } from '../utils/apiUtils';
import CollectionTab, { CollectionTabProps, collectionPapersPairs } from '../components/CollectionTab';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import BreakpointUtils from '../utils/BreakpointUtils';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import { Close, FullscreenExit } from '@mui/icons-material';
import { scrollTo } from '../pages/DigestPage';
import AbcTwoToneIcon from '@mui/icons-material/AbcTwoTone';
import SortIcon from '@mui/icons-material/Sort';
import RestoreIcon from '@mui/icons-material/Restore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { experimentalStyled as styled } from '@mui/material/styles';
// import ChipDelete from '@mui/joy/ChipDelete';


// dnd imports
import {
  DndContext,
  closestCenter,
  MouseSensor as LibMouseSensor,
  KeyboardSensor as LibKeyboardSensor,
  PointerSensor as LibPointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  useSortable
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { set } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompactPaper from '../components/paper/CompactPaper';



interface collectionNamesToIdsDict {
  collection: string;
  collectionId: number;
}

interface collectionIdsToNamesDict {
  collectionId: string;
  collection: string;
}

interface collectionsMetaDict {
  collectionId: number;
  expanded: boolean;
  lastExpandingDate?: string;
}

interface AutocompleteOption {
  collectionName: string;
  label: string;
}

export function scrollAfterAdditionalLoading(scrollToY: number, pageHightBefore: number, delay: number) {
  setTimeout(() => {
    const pageHightNow = document.documentElement.scrollHeight;
    if (pageHightNow >= pageHightBefore) {
      window.scrollTo(0, scrollToY);
    } else {
      scrollAfterAdditionalLoading(scrollToY, pageHightBefore, delay);
    }
  }, delay);
}

const COLLECTION_CHANGE_DELAY = 1000;

export default function CollectionsPage() {
  // screen size
  const trimControlsBar = BreakpointUtils.lessThanMedium();
  const trimToMiddleScreen = BreakpointUtils.lessThanLarge();
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);
  const [collectionDisplayList, setCollectionDisplayList] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState('collections_papers');
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const [similarPapersFeed, setSimilarPapersFeed] = useState<PaperProps[]>([]);
  const [mainPageLoading, setMainPageLoading] = useState<boolean>(true);
  const [similarPapersLoading, setSimilarPapersLoading] = useState<boolean>(false);
  const [paging, setPaging] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [collectionNamesIdsDict, setCollectionNamesIdsDict] = useState<collectionNamesToIdsDict[]>([]);
  const [collectionIdsNamesDict, setCollectionIdsNamesDict] = useState<collectionIdsToNamesDict[]>([]);
  const [collectionsMeta, setCollectionMeta] = useState<collectionsMetaDict[]>([]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [recentCollections, setRecentCollections] = useState<collectionsMetaDict[]>([]);
  const [sortPapersMode, setSortPapersMode] = useState('title');
  const [collectionGridColumnsNumber, setCollectionGridColumnNumber] = useState<number>(BreakpointUtils.lessThanLarge() ? 3 : 4);
  const [expandCollectionGrid, setExpandCollectionGrid] = useState(false);
  const [controlBoxHight, setControlBoxHight] = useState(65);
  const [papersToExclude, setPapersToExclude] = useState<collectionPapersPairs[]>([]);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      // setCollectionsFeed(mockDigestData);
      return;
    }
    console.log('handleDataFeedUpdate:', response)
    //filling the coll_ids/names dictionaries
    const allUserCollectionsNamesToIds = response.collection_names_to_ids_dict;
    setCollectionNamesIdsDict(allUserCollectionsNamesToIds);
    const allUserCollectionsIdsToNames = response.collection_ids_to_names_dict;
    setCollectionIdsNamesDict(allUserCollectionsIdsToNames);
    //filling the collections meta data
    const collectionsMetaList: collectionsMetaDict[] = [];
    response.collections_meta.map((coll: any) => {
      const collMeta: collectionsMetaDict = {
        collectionId: coll.user_collection_id,
        expanded: coll.expanded,
        lastExpandingDate: coll.last_expanding_date
      }
      collectionsMetaList.push(collMeta);
    });
    setCollectionMeta(collectionsMetaList);
    // console.log('datafeed', collectionsMetaList.filter((meta) => meta.lastExpandingDate !== null).sort(function (a, b) { return (a.lastExpandingDate < b.lastExpandingDate ? 1 : -1) }).slice(0, 5).map((meta) => (allUserCollectionsIdsToNames[meta.collectionId])));
    setRecentCollections(collectionsMetaList.filter((meta) => meta.lastExpandingDate !== null).sort(function (a, b) { return (a.lastExpandingDate < b.lastExpandingDate ? 1 : -1) }).slice(0, 5));
    setCollectionDisplayList(collectionsMetaList.filter((meta) => meta.expanded === true).map((coll) => allUserCollectionsIdsToNames[coll.collectionId]));
    setMainPageLoading(false);
  };

  // similar papers view
  const handleViewModeSelect = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
      // setExpandCollectionGrid(false);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // console.log('DEBUG:', collectionDisplayList.map((collection) => collectionNamesIdsDict[collection]));
      if (newViewMode === 'similar_papers') {
        loadRelevantSimilarPapers(collectionDisplayList);
      }
    }
  };

  const loadRelevantSimilarPapers = (collectionsList: string[]) => {
    console.log('loadRelevantSimilarPapers:', collectionsList);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    if (collectionNamesIdsDict.length === 0) {
      // get metadata in case of debounced usage
      const collectionsData = GetCollectionsData();
      collectionsData.then(res => {
        const collectionNamesIdsDict = res.collection_names_to_ids_dict;
        const collectionIds = collectionsList.map((collection) => collectionNamesIdsDict[collection]);
        const similarPapersData = GetCollectionsSimilarPapers(collectionIds, paging);
        // console.log('loadRelevantSimilarPapers:', collectionNamesIdsDict, collectionIds, similarPapersData);
        similarPapersData.then(res => {
          handleSimilarPapersFeed(res);
          setSimilarPapersLoading(false);
        });
        setSimilarPapersLoading(true);
      });
    } else {
      const collectionIds = collectionsList.map((collection) => collectionNamesIdsDict[collection]);
      const similarPapersData = GetCollectionsSimilarPapers(collectionIds, paging);
      similarPapersData.then(res => {
        handleSimilarPapersFeed(res);
        setSimilarPapersLoading(false);
      });
      setSimilarPapersLoading(true);
    }
  }

  const debouncedLoadRelevantSimilarPapers = useMemo(
    () => debounce((coll: string[]) => { loadRelevantSimilarPapers(coll); }, COLLECTION_CHANGE_DELAY)
    , []);

  const handleSimilarPapersFeed = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      setSimilarPapersFeed(mockDigestData);
      return;
    }
    // build digest data
    // console.log('handleSimilarPapersFeed:', stringArrayEquals(response.queried_collections, collectionDisplayList), response.queried_collections, collectionDisplayList);
    const digest: PaperProps[] = responseSetPaperProps(response);
    setSimilarPapersFeed(digest);
    setPaging(1);
    setHasNextPage(response.has_next);
  };

  // infinite scroll for similar papers functions 
  const fetchNextPage = () => {
    console.log('fetching next', collectionDisplayList);

    const collectionIds = collectionDisplayList.map((collection) => collectionNamesIdsDict[collection]);
    const similarPapersData = GetCollectionsSimilarPapers(collectionIds, paging);
    similarPapersData.then(response => {
      let digest = responseSetPaperProps(response);
      console.log(digest);
      setSimilarPapersFeed(similarPapersFeed.concat(digest));
      setHasNextPage(response.has_next);
      setPaging(paging + 1);
    });

    console.log('done next');
  }

  // collection selection
  const handleAutocompleteChange = (event: any, collections: AutocompleteOption[] | null) => {
    // console.log('handleSelectChange:', event.target.value, collections);
    if (collections == null) {
      return;
    }
    const selectedCollections = collections.map((collection) => collection.collectionName);
    setCollectionDisplayList(selectedCollections);
    // clean up selectedAutocompleteOptions/collectionDisplayList from closed collections
    const collectionsToCollapse = selectedAutocompleteOptions.filter(value => !collections.includes(value));
    for (let i = 0; i < collectionsToCollapse.length; i++) {
      CollapseCollectionTab(collectionNamesIdsDict[collectionsToCollapse[i].collectionName]);
      setCollectionMeta(collectionsMeta.map((meta) => {
        if (meta.collectionId === collectionNamesIdsDict[collectionsToCollapse[i].collectionName]) {
          meta.expanded = false;
        }
        return meta;
      }
      ));
    }
    // add newly added collections to selectedAutocompleteOptions/collectionDisplayList
    const collectionsToExpand = collections.filter(value => !selectedAutocompleteOptions.includes(value));
    for (let i = 0; i < collectionsToExpand.length; i++) {
      ExpandCollectionTab(collectionNamesIdsDict[collectionsToExpand[i].collectionName]);
      setCollectionMeta(collectionsMeta.map((meta) => {
        if (meta.collectionId === collectionNamesIdsDict[collectionsToExpand[i].collectionName]) {
          meta.expanded = true;
        }
        return meta;
      }
      ));
      scrollToCollectionTab(collectionsToExpand[i].collectionName, 500);
    }
    setSelectedAutocompleteOptions(collections);

    if (viewMode === 'similar_papers') {
      debouncedLoadRelevantSimilarPapers(selectedCollections);
    }
  }

  const handleCollectionTabClick = (collectionName: string, selectedOptions: AutocompleteOption[]) => {
    // update the list of displayed collections
    // console.log('handleCollectionTabClick0:',collectionName, selectedAutocompleteOptions, selectedOptions);
    if (selectedOptions.filter((option) => option.collectionName === collectionName).length === 0) {
      // expand new collection
      const newSelectedOptions = selectedOptions;
      newSelectedOptions.push({ collectionName: collectionName, label: collectionName.substring(0, 9) + ((collectionName.length > 9) ? '...' : '') });
      ExpandCollectionTab(collectionNamesIdsDict[collectionName]);
      scrollToCollectionTab(collectionName, 500);
      setCollectionMeta(collectionsMeta.map((meta) => {
        if (meta.collectionId === collectionNamesIdsDict[collectionName]) {
          meta.expanded = true;
        }
        return meta;
      }
      ));
      // TODO: clean and optimize code
      setSelectedAutocompleteOptions(newSelectedOptions);
      setCollectionDisplayList(newSelectedOptions.map((collection) => collection.collectionName));
      // update recent collections manually 
      const newRecentCollection = collectionsMeta.filter((meta) => meta.collectionId === collectionNamesIdsDict[collectionName]);
      if (recentCollections.includes(newRecentCollection[0])) {
        recentCollections.splice(recentCollections.indexOf(newRecentCollection[0]), 1);
        setRecentCollections(newRecentCollection.concat(recentCollections));
      } else {
        setRecentCollections(newRecentCollection.concat(recentCollections.slice(0, 4)));
      }
      // reload the similar feed if needed
      if (viewMode === 'similar_papers') {
        // loadRelevantSimilarPapers(newSelectedOptions.map((collection) => collection.collectionName));
        debouncedLoadRelevantSimilarPapers(newSelectedOptions.map((collection) => collection.collectionName));
        // console.log(loading, similarPapersFeed)
      }
    }
    else {
      // collapse collection
      CollapseCollectionTab(collectionNamesIdsDict[collectionName]);
      setCollectionMeta(collectionsMeta.map((meta) => {
        if (meta.collectionId === collectionNamesIdsDict[collectionName]) {
          meta.expanded = false;
        }
        return meta;
      }
      ));
      const newReducedSelectedOptions = selectedOptions.filter((option) => option.collectionName !== collectionName);
      setSelectedAutocompleteOptions(newReducedSelectedOptions);
      setCollectionDisplayList(newReducedSelectedOptions.map((collection) => collection.collectionName));
      // reload the similar feed if needed or switch to collections view if no collections are selected
      if (viewMode === 'similar_papers' && newReducedSelectedOptions.length > 0) {
        // console.log('DEBUG:', newReducedSelectedOptions.map((collection) => collection.collectionName));
        debouncedLoadRelevantSimilarPapers(newReducedSelectedOptions.map((collection) => collection.collectionName));
        // loadRelevantSimilarPapers(newReducedSelectedOptions.map((collection) => collection.collectionName));
      } else if (viewMode === 'similar_papers' && newReducedSelectedOptions.length === 0) {
        setViewMode('collections_papers');
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      }
    }
    // focus on the the search bar to show the updated list of selected collections
    document.getElementById("Autoselect").click();
    // console.log('handleCollectionTabClick1:', selectedAutocompleteOptions);
  }

  const scrollToCollectionTab = (collectionName: string, delay: number) => {
    // timeout to wait for the collection to be rendered
    // console.log('Scrolling to:', collectionIdsNamesDict[collectionId]);
    const elementId = "coll-tab-" + collectionName;
    setTimeout(function () {
      var element = document.getElementById(elementId);
      if (element) {
        scrollTo(collectionName, -145, "smooth");
      } else {
        scrollToCollectionTab(collectionName, delay);
      }
    }, delay);
  };


  const handleSortModeChange = (event, newSortMode) => {
    if (newSortMode !== null) {
      setSortPapersMode(newSortMode);
      // console.log('DEBUG:', collectionDisplayList.map((collection) => collectionNamesIdsDict[collection]));
      if (viewMode === 'similar_papers') {
        loadRelevantSimilarPapers(collectionDisplayList);
      }
    }
  };


  function autoOptionsArrayEquals(a: AutocompleteOption[], b: AutocompleteOption[]) {
    const aNames = a.map((option) => option.collectionName);
    const bNames = b.map((option) => option.collectionName);
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      aNames.every(name => bNames.includes(name)) &&
      bNames.every(name => aNames.includes(name));
  }

  // function stringArrayEquals(a: string[], b: string[]) {
  //   return Array.isArray(a) &&
  //     Array.isArray(b) &&
  //     a.length === b.length &&
  //     a.every(name => b.includes(name)) &&
  //     b.every(name => a.includes(name));
  // }

  // load initial digest data
  useEffect(() => {
    // get metadata
    const collectionsData = GetCollectionsData();
    collectionsData.then(res => {
      handleDataFeedUpdate(res);
    });
    // get feature toggles
    const featureTogglesData = GetFeatureToggles();
    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });
    // get all user collections and fill the autocomplete options
    const allUserCollectionsRow = GetAllUserCollections();
    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });
    allUserCollectionsRow.then(response => {
      setAutocompleteOptions(response.map((collection: string) => ({
        collectionName: collection,
        label: collection.substring(0, 9) + ((collection.length > 9) ? '...' : '')
      })));
    });
  }, []);

  useEffect(() => {
    // use saved expanded collections list
    const expandedCollectionsList = GetUserExpandedCollections();
    expandedCollectionsList.then(response => {
      //update in case of change
      const tempSelected: AutocompleteOption[] = response.expanded_collections.map((collection: string) => ({
        collectionName: collection,
        label: collection.substring(0, 9) + ((collection.length > 9) ? '...' : '')
      }));
      // console.log('2nd useef:', tempSelected, selectedAutocompleteOptions);
      if (autoOptionsArrayEquals(tempSelected, selectedAutocompleteOptions) === false) {
        setSelectedAutocompleteOptions(tempSelected);
        setCollectionDisplayList(tempSelected.map((collection) => collection.collectionName));
      }
    });
  }, [selectedAutocompleteOptions]);

  useEffect(() => {
    // update meta in case of selected collections change
    const collectionsData = GetCollectionsData();
    // console.log('3d useef:', selectedAutocompleteOptions)
    collectionsData.then(res => {
      handleDataFeedUpdate(res);
    });
  }, [selectedAutocompleteOptions]);

  useEffect(() => {
    // update view according to the screen size
    if (trimToMiddleScreen) {
      setCollectionGridColumnNumber(3);
    } else {
      setCollectionGridColumnNumber(4);
    }
    // console.log('Breakpoint change:', trimToMiddleScreen, collectionGridColumnsNumber);
  }, [trimToMiddleScreen]);

  useEffect(() => {
    // console.log('grid expand usef:', collectionDisplayList, mainPageLoading, expandCollectionGrid);
    if (collectionDisplayList.length === 0 && mainPageLoading != true) {
      setExpandCollectionGrid(true);
    }
  }, [collectionDisplayList]);

  useEffect(() => {
    setControlBoxHight(document.getElementById("controlBox")?.getBoundingClientRect().height);
  }, [collectionDisplayList]);

  const isCompactViewEnabled = featureToggles.some(
    (toggle) => toggle.toggle_name === 'compact_paper_view' && toggle.is_toggled
  );

  //displayed elements
  const CollectionGridItem = styled(MuiPaper)(({ theme }) => ({
    // backgroundColor: expanded ? '#A7AAAD': '#f0f4f9',
    backgroundColor: '#f0f4f9',
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'theme.palette.text.secondary',
    boxShadow: '0 0px 0px 0 rgba(0,0,0,0.1)',
    borderRadius: '8px',
    cursor: 'pointer',
  }));

  let CollectionsGridDesktop = allUserCollections ?
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 1 }}>
      {/* all collections grid */}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'start', alignSelf: allUserCollections.length > 0 ? 'start' : 'center', gap: 1, mt: 1, width: '80%', }}>
        {allUserCollections.length === 0 ? (
          <Typography variant='body1' sx={{ textAlign: 'center', color: 'grey', alignSelf: 'center', alignItems: 'center' }}>Your paper collections.</Typography>
        ) : (
          <>{Array(collectionGridColumnsNumber).fill(0).map((_, i) => i).map((i) => (
            <Grid container direction="column"
              spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {allUserCollections.slice(i * Math.ceil(allUserCollections.length / collectionGridColumnsNumber), (i + 1) * Math.ceil(allUserCollections.length / collectionGridColumnsNumber)).map(coll => (
                <Grid item xs={2} sm={4} md={4} key={coll}>
                  <CollectionGridItem sx={{ backgroundColor: `${collectionDisplayList.includes(coll) ? '#CED0D2' : '#f0f4f9'}` }}
                    onClick={() => handleCollectionTabClick(coll, selectedAutocompleteOptions)}>{coll}</CollectionGridItem>
                </Grid>
              ))}
            </Grid>))
          }</>
        )}
      </Box>
      {/* Recent collections */}
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '20%', alignSelf: 'start', mt: 1, justifyContent: 'center',
        boxShadow: '1px 4px 4px 1px rgba(0,0,0,0.1)', borderRadius: '6px', p: 1
      }}>
        <Box id='recent' sx={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', gap: 1, justifyContent: 'center', }}>
          <AccessTimeIcon sx={{ mb: 1 }} /> <Typography variant='h6' sx={{ textAlign: 'center', mb: 1 }}> Recent</Typography>
        </Box>
        {recentCollections.length === 0 ? (
          <Typography variant='body1' sx={{ textAlign: 'center', color: 'grey' }}>Recently opened collections.</Typography>
        ) : (
          <Grid container direction="column"
            spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ ml: 1 }}>
            {recentCollections.map((meta) => (
              <Grid item xs={2} sm={1} md={1} key={meta.collectionId}>
                <CollectionGridItem sx={{ backgroundColor: `${collectionDisplayList.includes(collectionIdsNamesDict[meta.collectionId.toString()]) ? '#CED0D2' : '#f0f4f9'}` }}
                  onClick={() => handleCollectionTabClick(collectionIdsNamesDict[meta.collectionId.toString()], selectedAutocompleteOptions)}>
                  {collectionIdsNamesDict[meta.collectionId.toString()]}</CollectionGridItem>
              </Grid>
            ))}
          </Grid>
        )}

      </Box>
    </Box> : <></>;

  let CollectionsGridMobile = allUserCollections ?
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
      {/* Recent collections */}
      {recentCollections.length > 0 && (
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignSelf: 'center', justifyContent: 'center',
          boxShadow: '0 4px 4px 0 rgba(0,0,0,0.1)', borderRadius: '6px', p: 1, flexGrow: 1, width: '100%'
        }}>

          <Box id='recent' sx={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', gap: 0.1, mt: -1, justifyContent: 'center', }}>
            <AccessTimeIcon sx={{}} /> <Typography variant='h6' sx={{ textAlign: 'center', mt: 1, mb: 1 }}> Recent</Typography>
          </Box>

          <Grid container direction="column"
            spacing={{ xs: 5, md: 0.1 }} rowSpacing={0.4} columns={{ xs: 10, sm: 8, md: 1 }} sx={{ ml: 1, mt: -2 }}>
            {recentCollections?.map((meta) => (
              <Grid item xs={2} sm={1} md={0.1} key={meta.collectionId}>
                <CollectionGridItem sx={{ flex: 1, backgroundColor: `${collectionDisplayList.includes(collectionIdsNamesDict[meta.collectionId.toString()]) ? '#CED0D2' : '#f0f4f9'}` }}
                  onClick={() => handleCollectionTabClick(collectionIdsNamesDict[meta.collectionId.toString()], selectedAutocompleteOptions)}>
                  {collectionIdsNamesDict[meta.collectionId.toString()]}</CollectionGridItem>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* all collections grid */}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'start', alignSelf: 'start', gap: 1, mt: 1, width: '100%', }}>
        {allUserCollections.length === 0 ? (
          <Typography variant='body1' sx={{ textAlign: 'center', mt: 2, color: 'grey' }}>Your paper collections.</Typography>
        ) : (
          <>{Array(2).fill(0).map((_, i) => i).map((i) => (
            <Grid container direction="column"
              spacing={{ xs: 2, md: 1 }} rowSpacing={0.5} columns={{ xs: 4, sm: 8, md: 12 }}>
              {allUserCollections.slice(i * Math.ceil(allUserCollections.length / 2), (i + 1) * Math.ceil(allUserCollections.length / 2)).map(coll => (
                <Grid item xs={2} sm={4} md={4} key={coll}>
                  <CollectionGridItem sx={{ backgroundColor: `${collectionDisplayList.includes(coll) ? '#CED0D2' : '#f0f4f9'}` }}
                    onClick={() => handleCollectionTabClick(coll, selectedAutocompleteOptions)}>{coll}</CollectionGridItem>
                </Grid>
              ))}
            </Grid>))
          }</>
        )}
      </Box>
    </Box> : <></>;
  // console.log('loading new collections:', collectionDisplayList, selectedAutocompleteOptions);

  const handleCollectionGridExpand = () => {
    setExpandCollectionGrid(true);
  }

  const handleCollectionGridCollapse = () => {
    if (collectionDisplayList.length > 0) {
      setExpandCollectionGrid(false);
    }
  }

  const updateChangedPapers = (collectionName: string, paperId: number) => {
    console.log('updateChangedPapers:', collectionName, paperId);
    const newPapersToExclude = papersToExclude;
    newPapersToExclude.push({ collectionName: collectionName, paperId: paperId });
    setPapersToExclude(newPapersToExclude);
  }


  let CollectionTabs = selectedAutocompleteOptions ?
    selectedAutocompleteOptions.map(option => option.collectionName).map(coll => (
      <Box id={coll} key={coll} >
        <CollectionTab collection={coll}
          expanded={collectionsMeta.filter((meta) => meta.collectionId === collectionNamesIdsDict[coll])[0]?.expanded}
          collectionId={collectionNamesIdsDict[coll]}
          allUserCollections={allUserCollections}
          featureToggles={featureToggles}
          sortMode={sortPapersMode}
          compactPapersMode={isCompactViewEnabled}
          updateChangedPapers={updateChangedPapers}
          papersToExclude={papersToExclude}
        ></CollectionTab>
      </Box>
    ))
    : <></>;


  let AutocomleteMobile = (
    <Autocomplete
      id="Autoselect"
      multiple
      disableClearable
      options={autocompleteOptions}
      getOptionLabel={(option) => option.collectionName}
      value={selectedAutocompleteOptions ? selectedAutocompleteOptions : []}
      isOptionEqualToValue={(option, value) => option.collectionName === value.collectionName}
      renderInput={(params) => (
        <TextField {...params} placeholder="Collections" />
      )}
      renderTags={(value) => <SortableChips values={value} />}
      sx={{ width: '100%', ml: 1, mr: 1, pr: 0.5, pl: 0.5, }}
      onChange={handleAutocompleteChange}
    />);

  let AutocomleteDesktop = (
    <Autocomplete
      id="Autoselect"
      multiple
      options={autocompleteOptions}
      disableClearable
      getOptionLabel={(option) => option.collectionName}
      value={selectedAutocompleteOptions ? selectedAutocompleteOptions : []}
      isOptionEqualToValue={(option, value) => option.collectionName === value.collectionName}
      renderInput={(params) => (
        <TextField {...params} placeholder="Collections" />
      )}
      renderTags={(value) => <SortableChips values={value} />}
      sx={{ width: '100%', mr: 2, ml: 1 }}
      onChange={handleAutocompleteChange}
    />
  );

  const ControlButtons = (<>
    {viewMode !== 'similar_papers' && (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* <Typography variant='body2' sx={{ lineHeight: "10px", fontSize: 10, color: 'grey' }}>Sorted by:</Typography> */}
          <ToggleButtonGroup
            value={sortPapersMode}
            exclusive
            onChange={handleSortModeChange}
            aria-label="Sorting_options"
            size="small"
            sx={{ height: '35px', }} //display: 'flex', flexDirection: 'column'
          >
            <ToggleButton value="title"><Tooltip title="Order papers alphabetically" arrow><AbcTwoToneIcon /></Tooltip></ToggleButton>
            <ToggleButton value="relevance"><Tooltip title="Order papers by relevance" arrow><SortIcon /></Tooltip></ToggleButton>
            <ToggleButton value="collection_addition_date"><Tooltip title="Order papers by collection entry time" arrow><RestoreIcon /></Tooltip></ToggleButton>
            <ToggleButton value="publication_date"><Tooltip title="Order papers by publication date" arrow><EventRepeatIcon /></Tooltip></ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>)
    }
    <ToggleButtonGroup
      value={viewMode}
      exclusive
      onChange={handleViewModeSelect}
      aria-label="View_mode"
      size="small"
      sx={{ mr: trimControlsBar ? 0.5 : 1, height: '35px', }}
    >

      <ToggleButton value="collections_papers" sx={{ width: trimControlsBar ? null : '100px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}><Tooltip title="Show papers from the selected collections" arrow>
        <Typography variant='button' sx={{ lineHeight: "15px", fontSize: 12, }}>Paper Collections</Typography>
      </Tooltip></ToggleButton>
      {collectionDisplayList.length > 0 ? (
        <ToggleButton value="similar_papers" color="success" sx={{ width: trimControlsBar ? null : '75px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}><Tooltip title="Show similar papers to the selected collections" arrow>
          <Typography variant='button' sx={{ lineHeight: "15px", fontSize: 12, }}>Similar papers</Typography></Tooltip></ToggleButton>) : (
        <Tooltip title="Select collection to view similar papers" arrow><Box>
          <ToggleButton value="similar_papers" color="success" disabled sx={{ height: '35px', width: trimControlsBar ? null : '75px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
            <Typography variant='button' sx={{ lineHeight: "15px", fontSize: 12 }}>
              Similar papers
            </Typography>
          </ToggleButton></Box></Tooltip>
      )}
    </ToggleButtonGroup>
  </>);

  // drag and drop elements and functionality
  const handleChipDelete = (collectionName) => {
    handleCollectionTabClick(collectionName, selectedAutocompleteOptions);
  }

  class MouseSensor extends LibMouseSensor {
    static activators = [
      {
        eventName: 'onMouseDown' as const,
        handler: ({ nativeEvent: event }: MouseEvent) => {
          return shouldHandleEvent(event.target as HTMLElement)
        }
      }
    ]
  }

  class PointerSensor extends LibPointerSensor {
    static activators = [
      {
        eventName: 'onPointerDown' as const,
        handler: ({ nativeEvent: event }: MouseEvent) => {
          return shouldHandleEvent(event.target as HTMLElement)
        }
      }
    ]
  }

  class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
      {
        eventName: 'onKeyDown' as const,
        handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
          return shouldHandleEvent(event.target as HTMLElement)
        }
      }
    ]
  }

  function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element

    while (cur) {
      if (cur.dataset && cur.dataset.noDnd) {
        return false
      }
      cur = cur.parentElement
    }
    return true
  }

  const SortableSingleChip = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({ id: props.value });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition
    };

    const onMouseDown = (e) => {
      // e.preventDefault();
      e.stopPropagation();
    };
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Chip
          key={props.value?.collectionName}
          label={trimControlsBar ? props.value?.label : props.value?.collectionName}
          onMouseDown={e => onMouseDown(e)}
          onDelete={(e) => {
            e.stopPropagation();
            handleChipDelete(props.value?.collectionName);
          }}
          deleteIcon={<Close data-no-dnd="true" />}
        // endDecorator={<ChipDelete data-no-dnd="true"/>}
        />
      </div>
    );
  };

  const SortableChips = (props) => {
    const sensors = useSensors(
      useSensor(MouseSensor), //PointerSensor
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates
      })
    );
    const handleDragEnd = (event) => {
      const { active, over } = event;

      if (active && over && active.id.collectionName !== over.id.collectionName) {
        // console.log('Setting new order', selectedAutocompleteOptions);
        setSelectedAutocompleteOptions((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    };
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        autoScroll={{ layoutShiftCompensation: false, acceleration: 1, enabled: false }}
      >
        <SortableContext
          items={props.values}
          strategy={horizontalListSortingStrategy}
        >
          {props.values.map((id) => (
            <SortableSingleChip key={id.collectionName} value={id} />
          ))}
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <Box>
      <BoxDefaultContent fullWidth>
        {/* less than 1200px wide screen - change to mobile layout */}
        {trimControlsBar && (
          // Mobile view bar
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              position: 'sticky',
              top: '60px', background: 'white', zIndex: '1',
              borderRadius: '8px',
              // boxShadow: 2,
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 1 }}>
              {ControlButtons}
            </Box>
            {AutocomleteMobile}
          </Box>
        )}
        {!trimControlsBar && (
          // Desktop view bar
          <Box onMouseOver={handleCollectionGridExpand} onMouseLeave={handleCollectionGridCollapse}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'center',
              // alignItems: 'center',
              // margin: 'auto',
              mb: 2,
              position: 'sticky',
              top: '60px', background: 'white', zIndex: '1', borderTopLeftRadius: '8px', borderTopRightRadius: '8px',
              borderBottomLeftRadius: !expandCollectionGrid ? '8px' : '0px', borderBottomRightRadius: !expandCollectionGrid ? '8px' : '0px',
              // boxShadow: 2,
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
              // width: { xs: '100%', md: '800px' }
            }}
          >
            <Box id="controlBox" sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              // margin: 'auto',
            }} >
              {AutocomleteDesktop}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ml: -1, gap: 1 }}>
                {ControlButtons}
              </Box>
            </Box>
            <Box sx={{
              top: (controlBoxHight - 8).toString() + 'px',
              background: 'white', position: 'absolute', width: '100%',
              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px',
              zIndex: '1', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px'
            }}>
              <Collapse in={expandCollectionGrid} >
                <Box sx={{ px: 0.5, py: 0.5, mt: -1 }}>
                  {/* <Divider /> */}
                  {CollectionsGridDesktop}
                </Box>
              </Collapse>
            </Box>
          </Box>
        )}

        <Box sx={{ my: 2 }}>
          {(viewMode === 'collections_papers') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
              {trimControlsBar ? (
                <>{(collectionDisplayList.length >= 0) && (
                  CollectionsGridMobile
                )}</>
              ) : (
                <>
                  {/*{(collectionDisplayList.length == 0) && (
                  CollectionsGridDesktop
                )} */}
                </>
              )}
              {CollectionTabs}
            </Box>
          )}
          {(viewMode === 'similar_papers') && (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
              {/* Similar papers */}
              {!similarPapersLoading && similarPapersFeed.length > 0 && (
                <InfiniteScroll
                  dataLength={similarPapersFeed.length}
                  next={fetchNextPage}
                  hasMore={hasNextPage}
                  style={{ overflow: 'visible' }}
                  loader={
                    <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
                      <TripleDotLoadingProgress />
                    </Box>
                  }
                >
                  {similarPapersFeed.map(paper => (
                    <CompactPaper {...paper}
                      key={paper.title}
                      featureToggles={featureToggles}
                      all_user_collections={allUserCollections}
                    />
                  ))}
                </InfiniteScroll>
                // similarPapersFeed?.map(paper => (
                //   <Paper {...paper} key={paper.title}
                //     all_user_collections={allUserCollections}
                //     featureToggles={featureToggles}></Paper>))
              )
              }
              {similarPapersLoading && (
                <Box sx={{ justifyContent: 'center', display: 'flex', height: '500px', mt: 45 }}>
                  <TripleDotLoadingProgress />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </BoxDefaultContent>
    </Box>
  );
}
