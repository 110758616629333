import { Box, Divider, FormControl, InputLabel, MenuItem, PaperProps, Select, SelectChangeEvent, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import { GetAllUserCollections, GetAuthorData } from '../services/DigestService';
import InfiniteScroll from 'react-infinite-scroll-component';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { extractFeatureToggles, FeatureToggle } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import CompactPaper from '../components/paper/CompactPaper';

const POSSIBLE_ORDERINGS = ['citations', 'personal relevance', 'publication date'];

export default function AuthorPage() {
  let { author_id } = useParams<{ author_id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorName, setAuthorName] = useState<string>('');
  const [citationCount, setCitationCount] = useState<number>(0);
  const [paperCount, setPaperCount] = useState<number>(0);
  const [hIndex, setHIndex] = useState<number>(0);
  const [paperData, setPaperData] = useState<PaperProps[]>([]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<string>(POSSIBLE_ORDERINGS[0]);
  const [paging, setPaging] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    const featureTogglesData = GetFeatureToggles();
    const allUserCollectionsRow = GetAllUserCollections();
    const req = GetAuthorData(Number(author_id), orderBy, 0);
    req.then(resp => {
      console.log(resp);
      if (resp.success) {
        setAuthorName(resp.name);
        setCitationCount(resp.citation_count);
        setPaperCount(resp.paper_count);
        setHIndex(resp.h_index);

        let digest = responseSetPaperProps(resp);
        setPaperData(paperData.concat(digest));
        setHasNextPage(resp.has_next);
        setPaging(paging + 1);
      }
    });

    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });
    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });
  }, []);

  const fetchNextPage = () => {
    const req = GetAuthorData(Number(author_id), orderBy, paging);
    req.then(resp => {
      if (resp.success) {
        let digest = responseSetPaperProps(resp);
        setPaperData(paperData.concat(digest));
        setHasNextPage(resp.has_next);
        setPaging(paging + 1);
      }
    });
  }

  const handleOrderingChange = (event: SelectChangeEvent) => {
    setLoading(true);
    setPaperData([]);
    const req = GetAuthorData(Number(author_id), event.target.value as string, 0);
    req.then(resp => {
      if (resp.success) {
        let digest = responseSetPaperProps(resp);
        setPaperData(digest);
        setHasNextPage(resp.has_next);
        setPaging(0);
        setLoading(false);
      }
    });
    setOrderBy(event.target.value as string);
  };

  return (
    <BoxDefaultContent fullWidth>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* author info section */}
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
          <Typography variant='h5'>{authorName}</Typography>
          <Typography variant='body1' sx={{ ml: 2 }}>Citation count: {citationCount}</Typography>
          <Typography variant='body1' sx={{ ml: 2 }}>h Index: {hIndex}</Typography>
          <Typography variant='body1' sx={{ ml: 2 }}>Paper count: {paperCount}</Typography>
        </Box>
        <Divider />
        {/* filters */}
        <Box sx={{ py: 1 }}>
          <FormControl
            margin={'dense'}
            size={'small'}
            sx={{
              width: {
                md: 250,
              },
              mr: 1
            }}>
            <InputLabel id="select-order-by-label" color='secondary'>Order by</InputLabel>
            <Select
              labelId="select-order-by-label"
              id="select-order-by"
              value={orderBy}
              label="Order by"
              color='secondary'
              onChange={handleOrderingChange}
              size={'small'}
            >
              {POSSIBLE_ORDERINGS.map((ordering) => (
                <MenuItem value={ordering}>{ordering}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* papers */}
        <Box>
          <InfiniteScroll
            dataLength={paperData.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            style={{ overflow: 'visible' }}
            loader={
              paperData.length > 0 &&
              <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
                <TripleDotLoadingProgress />
              </Box>
            }
          >
            {paperData.map((paper, idx) => (
              <CompactPaper {...paper}
                key={paper.title}
                featureToggles={featureToggles}
                all_user_collections={allUserCollections}
              />
            ))}
          </InfiniteScroll>
        </Box>
      </Box>
    </BoxDefaultContent>
  )
}
