import React, { useState } from 'react';
import { IconButton, Tooltip, Menu, MenuItem, Box, Link, TextField, Typography, InputAdornment, Popover, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';
import { createSvgIcon } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkIcon from '@mui/icons-material/Link';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Helmet } from 'react-helmet';


const XIcon = createSvgIcon(
  <path d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z" />,
  'Twitter'
);

const BlueskyIcon = createSvgIcon(
  <path d="M12 10.8c-1.087 -2.114 -4.046 -6.053 -6.798 -7.995C2.566 0.944 1.561 1.266 0.902 1.565 0.139 1.908 0 3.08 0 3.768c0 0.69 0.378 5.65 0.624 6.479 0.815 2.736 3.713 3.66 6.383 3.364 0.136 -0.02 0.275 -0.039 0.415 -0.056 -0.138 0.022 -0.276 0.04 -0.415 0.056 -3.912 0.58 -7.387 2.005 -2.83 7.078 5.013 5.19 6.87 -1.113 7.823 -4.308 0.953 3.195 2.05 9.271 7.733 4.308 4.267 -4.308 1.172 -6.498 -2.74 -7.078a8.741 8.741 0 0 1 -0.415 -0.056c0.14 0.017 0.279 0.036 0.415 0.056 2.67 0.297 5.568 -0.628 6.383 -3.364 0.246 -0.828 0.624 -5.79 0.624 -6.478 0 -0.69 -0.139 -1.861 -0.902 -2.206 -0.659 -0.298 -1.664 -0.62 -4.3 1.24C16.046 4.748 13.087 8.687 12 10.8Z"/>, 
  'BlueSky'
);

const SCHOLAR_INBOX_PAPERS_URL = 'https://www.scholar-inbox.com/papers/';

// Constants and helpers from CompactPaper.tsx
const MendeleyTooltip = React.forwardRef(function MendeleyTooltip(props, ref) {
  return (
    <span style={{ textDecoration: 'underline', textDecorationStyle: 'dashed', textDecorationColor: '#b2b2b2' }} {...props} ref={ref as React.RefObject<HTMLDivElement>}>
      Mendeley
    </span>
  );
});

const MENDELEY_TECHNICAL_LIMITATIONS_TEXT = "Due to technical limitations with Mendeley, if you want to add another paper or your extension doesn't pick this one up - please refresh the page, click on the add button and then open the extension.";

const _processBibtexTitle = (title: string): string => {
  const words = title.split(/\s+/);
  const wrappedTitle: string[] = [];

  for (const word of words) {
    if (/\b[A-Z][a-z]*[A-Z]+\w*\b/.test(word)) {
      wrappedTitle.push(`{${word}}`);
    } else {
      wrappedTitle.push(word);
    }
  }

  return wrappedTitle.join(' ');
}

function _formatBibtexAuthors(authors: string): string {
  // Check if the input uses the "Lastname, Firstname and ..." format
  const isLastnameFirstFormat = authors.includes(" and ");

  if (isLastnameFirstFormat) {
    // Format: "Lastname1, Firstname1 and Lastname2, Firstname2 and ..."
    return authors
      .split(" and ")
      .map((author) => {
        const [lastName, firstName] = author.split(", ").map((part) => part.trim());
        return `${firstName} ${lastName}`.trim();
      })
      .join(" and ");
  } else {
    // Format: "FirstName1 LastName1, FirstName2 LastName2, ..."
    return authors.split(",").map((author) => author.trim()).join(" and ");
  }
}

function _formatPlainTextAuthors(authors: string): string {
  // Check if the input uses the "Lastname, Firstname and ..." format
  const isLastnameFirstFormat = authors.includes(" and ");

  if (isLastnameFirstFormat) {
    // Format: "Lastname1, Firstname1 and Lastname2, Firstname2 and ..."
    const authorArray = authors.split(" and ").map((author) => {
      const [lastName, firstName] = author.split(", ").map((part) => part.trim());
      return `${firstName} ${lastName}`.trim();
    });

    // Handle single or multiple authors
    if (authorArray.length === 1) {
      return authorArray[0];
    }

    const lastAuthor = authorArray.pop();
    return `${authorArray.join(", ")} and ${lastAuthor}`;
  } else {
    // Format: "FirstName1 LastName1, FirstName2 LastName2, ..."
    const authorArray = authors.split(",").map((author) => author.trim());

    // Handle single or multiple authors
    if (authorArray.length === 1) {
      return authorArray[0];
    }

    const lastAuthor = authorArray.pop();
    return `${authorArray.join(", ")} and ${lastAuthor}`;
  }
}

// Generate bibtex and plain text content
const generateBibtex = (props: any): string => {
  try {
    let generatedBibtex = '';
    if (props.hideControls || props.failedPaper) { return generatedBibtex; }

    const mainAuthorLastName = props.authors?.split(',')[0].split(' ').splice(-1)[0] ?? '';
    let year = props.conferenceName ? String(props.conference_year) : props.submittedDate?.split('-')[0] ?? '';
    if (year === ' ' || year === 'null') {
      year = String(props.conference_year) || props.submittedDate?.split('-')[0] || '';
    }

    const words = props.title.replace(/[^0-9a-zA-Z\s]/g, " ").split(" ").filter(word => word.length > 0);
    const titleSnippet = words.slice(0, 3).join("_");

    const shortId = mainAuthorLastName + year + props.dateVenue?.split(' ')[0].toUpperCase() + '_' + titleSnippet;
    const authors = _formatBibtexAuthors(props.authors);

    if (props.conferenceName) {
      generatedBibtex += '@inproceedings{';
      generatedBibtex += shortId + ',\n';
      generatedBibtex += '  ' + 'author={' + authors + '},\n';
      generatedBibtex += '  ' + 'title={' + _processBibtexTitle(props.title) + '},\n';
      generatedBibtex += '  ' + 'booktitle={' + props.conferenceName + '},\n';
      generatedBibtex += '  ' + 'year={' + year + '}\n';
      generatedBibtex += '}';
    } else {
      // journal case
      generatedBibtex += '@article{';
      generatedBibtex += shortId + ',\n';
      generatedBibtex += '  ' + 'author={' + authors + '},\n';
      generatedBibtex += '  ' + 'title={' + _processBibtexTitle(props.title) + '},\n';
      generatedBibtex += '  ' + 'journal={arXiv},\n';
      generatedBibtex += props.arxivLink && props.arxivLink.split('/') ? '  ' + 'volume={' + props.arxivLink.split('/').slice(-1) + '},\n' : '';
      generatedBibtex += '  ' + 'year={' + year + '}\n';
      generatedBibtex += '}';
    }

    return generatedBibtex;
  }
  catch (e) {
    console.log(e);
    return '';
  }
};

const generatePlainText = (props: any): string => {
  let generatedPlainText = '';

  generatedPlainText += _formatPlainTextAuthors(props.authors) + ': ';
  generatedPlainText += props.title + '. ';
  generatedPlainText += props.dateVenue === 'Semantic Scholar' ? props.dateVenue + '.' : props.dateVenue?.split(' ')[0] + ', ' + props.dateVenue?.split(' ')[1] + '.';

  return generatedPlainText;
};

// Facebook Dialog component
const FacebookDialogElement = (props) => {
  const { open, onClose, message, setMessage, continueToFacebook } = props;
  
  return (
    <Dialog
      sx={{ '& .MuiPaper-root': { borderRadius: '8px' } }}
      open={open}
      onClose={onClose}
      aria-labelledby="facebook-share-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="facebook-share-dialog-title">
        Share on Facebook
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Facebook will only show a preview of the URL, but we've prepared a message with the paper details:
        </Typography>
        <TextField
          multiline
          rows={3}
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant="outlined"
          sx={{ mb: 2, bgcolor: '#f5f5f5' }}
        />
        <Typography variant="body2" color="textSecondary">
          This message will be copied to your clipboard. You can paste it into your Facebook post.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          size="small"
          sx={{ 
            p: 1.5,
            transform: 'scale(0.9)',
            color: "action.active",
            "&:hover": { color: "secondary.main" }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={continueToFacebook} 
          size="small"
          sx={{ 
            p: 1.5,
            transform: 'scale(0.9)',
            color: "action.active",
            "&:hover": { color: "secondary.main" }
          }}
        >
          Continue to Facebook
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * ShareMenu component for sharing paper links on various social media platforms
 * 
 * @param {Object} props Component props
 * @param {string} props.title Paper title
 * @param {string} props.authors Paper authors
 * @param {string} props.arxivLink Link to the paper on arXiv
 * @param {string} props.mainText Abstract
 * @param {boolean} props.is_cached Whether the paper is cached
 * @param {string} props.is_cached_filename Filename of the cached paper
 * @param {boolean} props.useScholarInboxPdfServer Whether to use the Scholar Inbox PDF server
 * @param {boolean} props.enableBibtex Whether to enable the bibtex button
 * @param {string} props.conferenceName conferenceName of the cached paper
 * @param {string} props.conference_year conference_year of the cached paper
 * @param {string} props.submittedDate submittedDate of the cached paper
 * @param {string} props.dateVenue dateVenue of the cached paper
 * @param {boolean} props.enableBibtex 
 * @param {boolean} props.enableMobileBibtexButton 
 * @param {boolean} props.enableBibtexButton 
 * @param {boolean} props.enableMobileShareOnSocialMediaButton 
 * @param {boolean} props.enableShareOnSocialMediaButton 
 * @returns {JSX.Element} ShareMenu component
 */

const ShareMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  const [bibtexButtonActive, setBibtexButtonActive] = useState(false);
  const [bibtexAnchorEl, setBibtexAnchorEl] = useState(null);
  const [facebookDialogOpen, setFacebookDialogOpen] = useState(false);
  const [shareMessage, setShareMessage] = useState('');
  const [shareUrl, setShareUrl] = useState('');

  
  const isUrlAvailable = (props.is_cached && props.useScholarInboxPdfServer) || props.arxivLink;
  const bibtexPopoverOpen = Boolean(bibtexAnchorEl);
  
  // Generate bibtex and plain text once
  const generatedBibtex = generateBibtex(props);
  const generatedPlainText = generatePlainText(props);

  const onMobile = typeof window !== 'undefined' && window.innerWidth < 1024; // Simplified mobile detection
  const showShareOnSocialMedia = ((onMobile && props.enableMobileShareOnSocialMediaButton) || 
                        (!onMobile && props.enableShareOnSocialMediaButton));
  
  const showBibtexButton = ((onMobile && props.enableMobileBibtexButton) || 
                          (!onMobile && props.enableBibtexButton));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const toggleBibtexButton = (event) => {
    setBibtexButtonActive(!bibtexButtonActive);
    setBibtexAnchorEl(event.currentTarget);

    navigator.clipboard.writeText(generatedBibtex);

    // fire zotero event to ping extension that we've updated metadata
    document.dispatchEvent(new Event('ZoteroItemUpdated', {
      bubbles: true,
      cancelable: true
    }));
  };

  const handleBibtexPopoverClose = () => {
    setBibtexAnchorEl(null);
    setBibtexButtonActive(false);
  };

  // Helper functions to build share URLs
  const buildTwitterLink = () => {
    const searchParam = new URLSearchParams();
    searchParam.append('text', props.authors?.split(',')[0]?.split(' ').at(-1) + ' et al. ' + props.title + '\n' 
      + (props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink)
    );
    return 'https://twitter.com/intent/tweet?' + searchParam;
  };

  const buildLinkedInLink = () => {
    const searchParam = new URLSearchParams();
    const shareText = `${props.authors?.split(',')[0]?.split(' ').at(-1)} et al. ${props.title}\n${props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}\nShared by: https://www.scholar-inbox.com`;
    searchParam.append('text', shareText);
    searchParam.append('url', props.is_cached && props.useScholarInboxPdfServer ? 
        SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : 
        props.arxivLink);
    return 'https://www.linkedin.com/sharing/share-offsite/?' + searchParam;
  };

  const buildBlueskyLink = () => {
    const searchParam = new URLSearchParams();
    searchParam.append('text', props.authors?.split(',')[0]?.split(' ').at(-1) + ' et al. ' + props.title + '\n'
      + (props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink));
    return 'https://bsky.app/intent/compose?' + searchParam;
  };

  // Handler functions for each platform
  const handleShareOnTwitter = () => {
    window.open(buildTwitterLink(), '_blank');
    handleClose();
  };

  const handleShareOnLinkedIn = () => {
    window.open(buildLinkedInLink(), '_blank');
    handleClose();
  };

  const handleShareOnFacebook = () => {
    const paperURL = props.is_cached && props.useScholarInboxPdfServer ? 
      SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : 
      props.arxivLink;
      
    const formattedAuthors = _formatPlainTextAuthors(props.authors);
    const message = `I'm reading "${props.title}" by ${formattedAuthors}`;
    
    setShareMessage(message);
    setShareUrl(paperURL);
    setFacebookDialogOpen(true);
    
    handleClose();
  };

  const handleContinueToFacebook = () => {
    // Copy the message to clipboard
    navigator.clipboard.writeText(shareMessage)
      .catch(err => console.error('Failed to copy text: ', err));
    
    // Build the Facebook share URL with just the paper URL
    const fbShareUrl = 'https://www.facebook.com/sharer/sharer.php?' + 
      new URLSearchParams({ u: shareUrl });
      
    window.open(fbShareUrl, '_blank');
    
    setFacebookDialogOpen(false);
  };

  const handleShareOnBluesky = () => {
    window.open(buildBlueskyLink(), '_blank');
    handleClose();
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link)
    setCopiedSnackbarOpen(true);
  };

  const facebookDialogElement = (
    <FacebookDialogElement
      open={facebookDialogOpen}
      onClose={() => setFacebookDialogOpen(false)}
      message={shareMessage}
      setMessage={setShareMessage}
      continueToFacebook={handleContinueToFacebook}
    />
  );

  // Render BiTeX Popover regardless of which UI mode we're in
  const bibtexPopoverElement = (
    <Popover
      id={props.title + 'popover'}
      open={bibtexPopoverOpen}
      anchorEl={bibtexAnchorEl}
      onClose={handleBibtexPopoverClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{ maxWidth: '90%' }}
    >
      <Box sx={{ p: '10px', display: 'flex', flexDirection: 'column', width: onMobile ? '320px' : '700px', maxWidth: '100%' }}>
        <Typography variant='body1'>
          This paper is now available in your Zotero/
          <Tooltip disableFocusListener disableTouchListener title={MENDELEY_TECHNICAL_LIMITATIONS_TEXT} placement="top" arrow>
            <MendeleyTooltip />
          </Tooltip> browser extension.
        </Typography>
        <Typography variant='body1'>
          This Bibtex has been copied to your clipboard.
          Use CTRL/CMD+V to paste it.
        </Typography>
        <Typography variant='body1' sx={{ mt: '5px' }}>
          <b>Bibtex:</b>
        </Typography>
        <TextField
          color='secondary'
          multiline
          defaultValue={generatedBibtex}
          sx={{
            backgroundColor: '#F4F4F7'
          }}
          inputProps={{
            readOnly: true,
            style: {
              fontFamily: 'Courier',
              lineHeight: '20px',
              margin: '-8px', // hack vs mui stubborn padding on root element
            }
          }}
          InputProps={{
            endAdornment:
              <InputAdornment position='end'>
                <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedBibtex) }}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>,
            sx: {
              alignItems: "flex-start",
            }
          }}
        />
        <Typography variant='body1' sx={{ mt: '8px' }}>
          <b>Plain text:</b>
        </Typography>
        <TextField
          color='secondary'
          multiline
          defaultValue={generatedPlainText}
          sx={{
            backgroundColor: '#F4F4F7'
          }}
          inputProps={{
            readOnly: true,
            style: {
              fontFamily: 'Courier',
              lineHeight: '20px',
              margin: '-8px', // hack vs mui stubborn padding on root element
            }
          }}
          InputProps={{
            endAdornment:
              <InputAdornment position='end'>
                <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedPlainText) }}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>,
            sx: {
              alignItems: "flex-start",
            }
          }}
        />
      </Box>
    </Popover>
  );

  const shareLinkButton = isUrlAvailable ? (
    <Tooltip arrow title="Copy pdf link for this paper" placement="top">
      <IconButton 
        onClick={() => { 
          copyLink(props.is_cached && props.useScholarInboxPdfServer 
            ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename 
            : props.arxivLink) 
        }}
        size="small"
        sx={{ 
          transform: 'scale(0.9)', 
          p: 1.5,
          color: "action.active",
          "&:hover": { color: "secondary.main" }
        }}
      >
        <LinkIcon />
      </IconButton>
    </Tooltip>
  ) : <></>;

  // Early return if everything is disabled
  if (!showShareOnSocialMedia && !showBibtexButton && !isUrlAvailable) {
    return null;
  }

  // If social media sharing is disabled
  if (!showShareOnSocialMedia) {
    // If only one button is available, show it directly without a menu
    if ((showBibtexButton && !isUrlAvailable) || (!showBibtexButton && isUrlAvailable)) {
      return (
        <Box sx={{ position: 'relative' }}>
          {/* Show bibtex button if it's the only one enabled */}
          {showBibtexButton && (
            <Tooltip arrow title='Copy bibtex or add this paper to Zotero/Mendeley' placement="top">
              <IconButton 
                sx={{ 
                  transform: 'scale(0.9)', 
                  width: '1.4em', 
                  height: '1.4em',
                  mt: 0.5,
                  color: bibtexButtonActive ? "secondary.main" : "action.active",
                    "&:hover": { color: "secondary.main" }
                }} 
                onClick={toggleBibtexButton}
              >
                <FormatQuoteIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* Show link share button if it's the only one available */}
          {isUrlAvailable && !showBibtexButton && shareLinkButton}
          {bibtexPopoverElement}
        </Box>
      );
    }
    
    // If both buttons are available, use a menu
    return (
      <>
        <Box sx={{ position: 'relative' }}>
          <Tooltip arrow title="Share options" placement="top">
            <IconButton 
              onClick={handleClick}
              sx={{ 
                width: '1.5em', 
                height: '1.5em',
                color: "action.active", 
                "&:hover": { color: "secondary.main" }
              }}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
          
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            sx={{
              '.MuiPaper-root': {
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                borderRadius: '8px',
              },
              '.MuiList-root': {
                padding: 0,
                display: 'flex',
              },
              '.MuiMenuItem-root, .MuiButtonBase-root': {
                padding: 0.5,
                minHeight: 'auto',
              }
            }}
          >
            {showBibtexButton && (
              <Tooltip arrow title='Copy bibtex or add this paper to Zotero/Mendeley' placement="top">
                <IconButton 
                  onClick={toggleBibtexButton} 
                  size="small"
                  sx={{ 
                    p: 1.5,
                    transform: 'scale(0.9)', 
                    color: "action.active",
                    "&:hover": { color: "secondary.main" }
                  }}
                >
                  <FormatQuoteIcon />
                </IconButton>
              </Tooltip>
            )}
            {isUrlAvailable && shareLinkButton}
          </Menu>
          
          {bibtexPopoverElement}
        </Box>
        
        {facebookDialogElement}

      </>
    );
  }

  // Otherwise show the share menu
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {/* Share menu button */}
        <Tooltip arrow title="Share this paper" placement="top">
          <IconButton 
            onClick={handleClick}
            sx={{ 
              width: '1.5em', 
              height: '1.5em',
              color: "action.active", 
              "&:hover": { color: "secondary.main" }
            }}
          >
            <ShareIcon />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            '.MuiPaper-root': {
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              borderRadius: '8px', // for curved edges
            },
            '.MuiList-root': {
              padding: 0,
              display: 'flex',
            },
            '.MuiMenuItem-root, .MuiButtonBase-root': {
              padding: 0.5,
              minHeight: 'auto',
            }
          }}
        >
          <Tooltip arrow title="Twitter" placement="top">
            <IconButton 
              onClick={handleShareOnTwitter} 
              size="small"
              sx={{ 
                p: 1.5,
                transform: 'scale(0.9)', 
                color: "action.active",
                "&:hover": { color: "secondary.main" }
              }}
            >
              <XIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="LinkedIn" placement="top">
            <IconButton 
              onClick={handleShareOnLinkedIn} 
              size="small"
              sx={{ 
                p: 1.5,
                transform: 'scale(0.9)', 
                color: "action.active",
                "&:hover": { color: "secondary.main" }
              }}
            >
              <LinkedInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Facebook" placement="top">
            <IconButton 
              onClick={handleShareOnFacebook} 
              size="small"
              sx={{ 
                p: 1.5,
                transform: 'scale(0.9)', 
                color: "action.active",
                "&:hover": { color: "secondary.main" }
              }}
            >
              <FacebookIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Bluesky" placement="top">
            <IconButton 
              onClick={handleShareOnBluesky} 
              size="small"
              sx={{ 
                p: 1.5,
                transform: 'scale(0.9)', 
                color: "action.active",
                "&:hover": { color: "secondary.main" }
              }}
            >
              <BlueskyIcon />
            </IconButton>
          </Tooltip>
          {/* Add BibTeX button to menu if enabled */}
          {showBibtexButton && (
            <Tooltip arrow title='Copy bibtex or add this paper to Zotero/Mendeley' placement="top">
              <IconButton 
              onClick={toggleBibtexButton} 
              size="small"
              sx={{ 
                p: 1.5,
                transform: 'scale(0.9)', 
                color: "action.active",
                "&:hover": { color: "secondary.main" }
              }}
            >
                <FormatQuoteIcon />
              </IconButton>
            </Tooltip>
          )}
          {shareLinkButton}
        </Menu>
        
        {/* BibTeX Popover - always present but only shown when activated */}
        {bibtexPopoverElement}

        {/* Bibtex popover */}
        <Popover
          id={props.title + 'popover'}
          open={bibtexPopoverOpen}
          anchorEl={bibtexAnchorEl}
          onClose={handleBibtexPopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{ maxWidth: '90%' }}
        >
          <Box sx={{ p: '10px', display: 'flex', flexDirection: 'column', width: onMobile ? '320px' : '700px', maxWidth: '100%' }}>
            <Typography variant='body1'>
              This paper is now available in your Zotero/
              <Tooltip disableFocusListener disableTouchListener title={MENDELEY_TECHNICAL_LIMITATIONS_TEXT} placement="top" arrow>
                <MendeleyTooltip />
              </Tooltip> browser extension.
            </Typography>
            <Typography variant='body1'>
              This Bibtex has been copied to your clipboard.
              Use CTRL/CMD+V to paste it.
            </Typography>
            <Typography variant='body1' sx={{ mt: '5px' }}>
              <b>Bibtex:</b>
            </Typography>
            <TextField
              color='secondary'
              multiline
              defaultValue={generatedBibtex}
              sx={{
                backgroundColor: '#F4F4F7'
              }}
              inputProps={{
                readOnly: true,
                style: {
                  fontFamily: 'Courier',
                  lineHeight: '20px',
                  margin: '-8px', // hack vs mui stubborn padding on root element
                }
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedBibtex) }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>,
                sx: {
                  alignItems: "flex-start",
                }
              }}
            />
            <Typography variant='body1' sx={{ mt: '8px' }}>
              <b>Plain text:</b>
            </Typography>
            <TextField
              color='secondary'
              multiline
              defaultValue={generatedPlainText}
              sx={{
                backgroundColor: '#F4F4F7'
              }}
              inputProps={{
                readOnly: true,
                style: {
                  fontFamily: 'Courier',
                  lineHeight: '20px',
                  margin: '-8px', // hack vs mui stubborn padding on root element
                }
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedPlainText) }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>,
                sx: {
                  alignItems: "flex-start",
                }
              }}
            />
          </Box>
        </Popover>
      </Box>

      {facebookDialogElement}

      {
        bibtexButtonActive && (
          <Helmet>
            <meta name="citation_title" content={props.title} />
            <meta name="citation_date" content={props.submittedDate} />
            <meta name="citation_abstract" content={props.mainText} />
            {props.authors.split(',').map(author => (
              <meta name="citation_author" content={author} />)
            )}
            {isUrlAvailable && <meta name="citation_pdf_url" content={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink} />}
            {props.dateVenue && props.conferenceName && <meta name="citation_conference_title" content={props.dateVenue} />}
            {props.conferenceName && <meta name="citation_conference" content={props.conferenceName} />}
            {!props.conferenceName && <meta name="citation_journal_title" content={props.dateVenue} />}
          </Helmet>
        )
      }
      
    </>
  );
};

export default ShareMenu;