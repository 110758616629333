import { Box, Icon, Snackbar, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetSessionData } from '../../services/AuthService';
import { GetFeatureToggles, SetFeatureToggles } from '../../services/SettingsService';
import { Alert } from './OnboardingSettingsPage';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ShareIcon from '@mui/icons-material/Share';
import { createSvgIcon } from '@mui/material';

// const HtmlIcon = createSvgIcon(
//   <path d="M19.835,2H4.163C3.542,2,3.059,2.54,3.128,3.156l1.794,16.149c0.047,0.42,0.343,0.77,0.749,0.886l6.042,1.726	c0.187,0.053,0.385,0.053,0.572,0l6.042-1.726c0.406-0.116,0.702-0.466,0.749-0.886L20.87,3.156C20.939,2.54,20.456,2,19.835,2z M15.947,8H9.219l0.201,2.31l6.077-0.02c0.001,0,0.002,0,0.003,0c0.279,0,0.545,0.117,0.734,0.322	c0.19,0.206,0.285,0.482,0.262,0.761l-0.383,4.609c-0.034,0.416-0.323,0.767-0.726,0.88l-3.117,0.877	c-0.088,0.024-0.18,0.037-0.271,0.037c-0.094,0-0.188-0.013-0.277-0.039l-3.091-0.894c-0.394-0.114-0.678-0.455-0.718-0.862	l-0.079-0.798c-0.055-0.55,0.347-1.039,0.896-1.094c0.541-0.045,1.04,0.348,1.094,0.896l0.013,0.124l2.166,0.626l2.174-0.611	l0.235-2.832l-5.906,0.019c-0.001,0-0.002,0-0.003,0c-0.519,0-0.951-0.396-0.996-0.913L7.132,7.087	C7.107,6.808,7.201,6.531,7.391,6.324S7.848,6,8.128,6h7.819c0.553,0,1,0.448,1,1S16.5,8,15.947,8z"></path>,
//   'Html'
// );

const HtmlIcon = createSvgIcon(
  <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 331.617">
    <path fill-rule="nonzero" d="M271.099 21.308C274.787 6.304 289.956-2.873 304.96.815c15.005 3.688 24.181 18.857 20.493 33.862l-68.491 275.632c-3.689 15.005-18.857 24.181-33.862 20.493-15.005-3.688-24.181-18.857-20.493-33.862l68.492-275.632zm-118.45 224.344c11.616 10.167 12.795 27.834 2.628 39.45-10.168 11.615-27.835 12.794-39.45 2.627L9.544 194.604C-2.071 184.437-3.25 166.77 6.918 155.155c.873-.997 1.8-1.912 2.767-2.75l106.142-93.001c11.615-10.168 29.282-8.989 39.45 2.626 10.167 11.616 8.988 29.283-2.628 39.45l-82.27 72.086 82.27 72.086zm243.524 42.077c-11.615 10.167-29.282 8.988-39.45-2.627-10.167-11.616-8.988-29.283 2.628-39.45l82.27-72.086-82.27-72.086c-11.616-10.167-12.795-27.834-2.628-39.45 10.168-11.615 27.835-12.794 39.45-2.626l106.142 93.001a28.366 28.366 0 012.767 2.75c10.168 11.615 8.989 29.282-2.626 39.449l-106.283 93.125z"/>
  </svg>,
  'Html'
);

interface FeatureToggles {
  desktop: FeatureToggle[];
  mobile: FeatureToggle[];
}

export interface FeatureToggle {
  toggle_name: string;
  display_name: string;
  toggle_id: number,
  admin_only: boolean;
  created_date: string;
  is_toggled: boolean;
  description: string;
  icon?: string;
}

interface SnackbarMessage {
  message: string;
  key: number;
}

interface IconMapping {
  [icon_name: string]: React.ReactNode;
}

const IconNameToIconComponentMapping: IconMapping = {
  'library_add': <FormatQuoteIcon />,
  'bookmark_add': <BookmarkAddOutlinedIcon />,
  'social_media_share': <ShareIcon />,
  'show_trending': <TimelineIcon />,
  'compact_paper_view': <ViewCompactIcon />,
  'html_add': <HtmlIcon />
}

const extractSplitFeatureToggles = (response: any) => {
  let toggles: FeatureToggles = { desktop: [], mobile: [] };
  response.map(toggleResponse => {
    const toggle = {
      toggle_name: toggleResponse.toggle_name,
      toggle_id: toggleResponse.toggle_id,
      admin_only: toggleResponse.admin_only,
      created_date: toggleResponse.created_date,
      is_toggled: Boolean(toggleResponse.is_toggled),
      description: toggleResponse.description,
      display_name: toggleResponse.display_name,
      icon: toggleResponse.icon,
    }
    if (toggle.toggle_name.startsWith('mobile_')) {
      toggles.mobile.push(toggle);
    } else {
      toggles.desktop.push(toggle);
    }
  });
  return toggles;
}


export const extractFeatureToggles = (response: any) => {
  const toggles: FeatureToggle[] = []
  response.map(toggle => {
    toggles.push({
      toggle_name: toggle.toggle_name,
      toggle_id: toggle.toggle_id,
      admin_only: toggle.admin_only,
      created_date: toggle.created_date,
      is_toggled: Boolean(toggle.is_toggled),
      description: toggle.description,
      display_name: toggle.display_name,
      icon: toggle.icon,
    });
  });
  return toggles;
}

export default function FeatureToggleSettingsPage() {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggles>({ desktop: [], mobile: [] });
  const [toggles, setToggles] = useState<boolean[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackbarOpen(true);
    } else if (snackPack.length && messageInfo && snackbarOpen) {
      // Close an active snack when a new one is added
      setSnackbarOpen(false);
    }
  }, [snackPack, messageInfo, snackbarOpen]);

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSettingsDataUpdate = (response) => {
    const data: FeatureToggles = extractSplitFeatureToggles(response);
    setFeatureToggles(data);
    setToggles(data.desktop.map(toggle => toggle.is_toggled).concat(data.mobile.map(toggle => toggle.is_toggled)));
  };

  useEffect(() => {
    const settingsData = GetFeatureToggles();
    settingsData.then(res => {
      handleSettingsDataUpdate(res);
    });
    const req = GetSessionData();
    req.then(res => {
      setIsAdmin(res.is_admin);
    })
  }, []);

  const handleToggle = (toggled: boolean, toggle_id: number, idx: number) => {
    const set_toggled = SetFeatureToggles(toggle_id, toggled);
    set_toggled.then(res => {
      let newToggles = [...toggles];
      newToggles[idx] = !toggles[idx];
      setToggles(newToggles);

      let msg = featureToggles[idx].display_name + (!toggles[idx] ? ' is now On' : ' is now Off');
      setSnackPack((prev) => [...prev, { message: msg, key: new Date().getTime() }]);
    });
  };

  return (
    <BoxSettingsContent>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          key={messageInfo ? messageInfo.key : undefined}
          TransitionProps={{ onExited: handleExited }}
          open={snackbarOpen}
          autoHideDuration={3000}
          message={messageInfo ? messageInfo.message : undefined}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            {messageInfo?.message}
          </Alert>
        </Snackbar>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          key={messageInfo ? messageInfo.key : undefined}
          TransitionProps={{ onExited: handleExited }}
          open={snackbarOpen}
          autoHideDuration={3000}
          message={messageInfo ? messageInfo.message : undefined}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            {messageInfo?.message}
          </Alert>
        </Snackbar>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            {/* toggle */}
            <TableCell></TableCell>
            {/* icon */}
            <TableCell></TableCell>
            <TableCell>Toggle name</TableCell>
            {isAdmin && (
              <>
                <TableCell>Admin Only</TableCell>
                <TableCell>Created On</TableCell>
              </>
            )}

            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Box sx={{ mt: 1, ml: 2 }}>
            <Typography variant='h6'>Desktop:</Typography>
          </Box>
          {featureToggles.desktop.map((feature, idx) => (
            <TableRow
              key={feature.toggle_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Switch
                  checked={toggles[idx]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleToggle(event.target.checked, feature.toggle_id, idx);
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell>
                {feature.icon ? IconNameToIconComponentMapping[feature.icon] ?? <></> : <></>}
              </TableCell>
              {isAdmin && (
                <>
                  <TableCell>{feature.display_name}</TableCell>
                  <TableCell>{feature.admin_only ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{feature.created_date}</TableCell>
                </>
              )}
              <TableCell>{feature.description}</TableCell>
            </TableRow>
          ))}
          <Box sx={{ mt: 2, ml: 2 }}>
            <Typography variant='h6'>Mobile:</Typography>
          </Box>
          {featureToggles.mobile.map((feature, idx) => (
            <TableRow
              key={feature.toggle_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Switch
                  checked={toggles[featureToggles.desktop.length + idx]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleToggle(event.target.checked, feature.toggle_id, featureToggles.desktop.length + idx);
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell>
                {feature.icon ? IconNameToIconComponentMapping[feature.icon] ?? <></> : <></>}
              </TableCell>
              {isAdmin && (
                <>
                  <TableCell>{feature.display_name}</TableCell>
                  <TableCell>{feature.admin_only ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{feature.created_date}</TableCell>
                </>
              )}
              <TableCell>{feature.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BoxSettingsContent>
  )
}
