import axiosInstance from "./axiosInstance";


export async function GetFilterData() {
  // this function is used to get the filter data for the search page
  // allows for faster scaling sources
  try {
    const response = await axiosInstance.get('/get_search_filters/');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}
export async function GetSearchData(searchPrompt: string,
  nResults: number,
  searchIn?: string,
  show?: string,
  orderBy?: string,
  paging?: number,
  conference_planner_id?: number,
) {
  try {
    const response = await axiosInstance.post('/get_search_results/', {
      search_prompt: searchPrompt,
      n_results: nResults,
      searchIn: searchIn,
      show: show,
      orderBy: orderBy,
      p: paging?? 0,
      ...(conference_planner_id ? { conference_planner_id: conference_planner_id } : {}),
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSeenSearchInfo() {
  try {
    const response = await axiosInstance.get('/get-seen-search-info/');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}