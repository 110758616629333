import React, { useEffect, useMemo, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import HideImageIcon from '@mui/icons-material/HideImage';
import AddIcon from '@mui/icons-material/Add';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  Autocomplete,
  Box,
  CardContent,
  Chip,
  Collapse,
  createSvgIcon,
  duration,
  Link,
  Paper,
  Popover,
  Popper,
  Stack,
  styled,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material';
import { colourListToColour, roundRelevance } from '../../utils/relevanceUtils';
import { setHighlightsForAuthorDetails, shortenAuthors, shortenAuthorsAndHighlightedIndices } from '../../utils/paperUtils';
import { MarkPaperAsRead, MarkPaperAsBookmarked, MakePaperRating, AddPaperCollection, RemovePaperCollection, MarkPaperAsPinnedForPlanner, GetPaperPinnedStatusForPlanner, MarkPosterAsPinned } from '../../services/DigestService';
import { PRIMARY_HIGHLIGHT_COLOUR, textToHighlightedElements, textToHighlightedElementsForSS } from '../../utils/highlightUtils';
import { scrollTo } from '../../pages/DigestPage';
import { FeatureToggle } from '../../pages/settings/FeatureToggleSettingsPage';
import { UpdateCollectionTab } from '../CollectionTab';
import { GetPosterDetails } from '../../services/ConferenceService';
import { PosterDetailsResponse } from '../conference/PosterComponent';
import TeaserFigure, { TeaserFigureProps } from '../TeaserFigure';
import BreakpointUtils from '../../utils/BreakpointUtils';
import SimilarPapers from '../SimilarPapers';
import PaperAbstractComponent from '../PaperAbstractComponent';
import ls from 'localstorage-slim';
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FunctionsIcon from "@mui/icons-material/Functions";
import ComputerIcon from "@mui/icons-material/Computer";
import BiotechIcon from "@mui/icons-material/Biotech";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import GTranslateIcon from "@mui/icons-material/GTranslate"; // Translate alternative
import EqualizerIcon from "@mui/icons-material/Equalizer";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices"; // Stethoscope alternative
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import MonitorIcon from "@mui/icons-material/Monitor"; // Monitoring alternative
import ScienceIcon from "@mui/icons-material/Science"; // Experiment alternative
import MyLocationIcon from '@mui/icons-material/MyLocation';
import ShareMenu from '../ShareMenu';

/// const HtmlIcon = createSvgIcon(
//   <path d="M19.835,2H4.163C3.542,2,3.059,2.54,3.128,3.156l1.794,16.149c0.047,0.42,0.343,0.77,0.749,0.886l6.042,1.726	c0.187,0.053,0.385,0.053,0.572,0l6.042-1.726c0.406-0.116,0.702-0.466,0.749-0.886L20.87,3.156C20.939,2.54,20.456,2,19.835,2z M15.947,8H9.219l0.201,2.31l6.077-0.02c0.001,0,0.002,0,0.003,0c0.279,0,0.545,0.117,0.734,0.322	c0.19,0.206,0.285,0.482,0.262,0.761l-0.383,4.609c-0.034,0.416-0.323,0.767-0.726,0.88l-3.117,0.877	c-0.088,0.024-0.18,0.037-0.271,0.037c-0.094,0-0.188-0.013-0.277-0.039l-3.091-0.894c-0.394-0.114-0.678-0.455-0.718-0.862	l-0.079-0.798c-0.055-0.55,0.347-1.039,0.896-1.094c0.541-0.045,1.04,0.348,1.094,0.896l0.013,0.124l2.166,0.626l2.174-0.611	l0.235-2.832l-5.906,0.019c-0.001,0-0.002,0-0.003,0c-0.519,0-0.951-0.396-0.996-0.913L7.132,7.087	C7.107,6.808,7.201,6.531,7.391,6.324S7.848,6,8.128,6h7.819c0.553,0,1,0.448,1,1S16.5,8,15.947,8z"></path>,
//   'Html'
// );

const HtmlIcon = createSvgIcon(
  <svg shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 331.617">
    <path fill-rule="nonzero" d="M271.099 21.308C274.787 6.304 289.956-2.873 304.96.815c15.005 3.688 24.181 18.857 20.493 33.862l-68.491 275.632c-3.689 15.005-18.857 24.181-33.862 20.493-15.005-3.688-24.181-18.857-20.493-33.862l68.492-275.632zm-118.45 224.344c11.616 10.167 12.795 27.834 2.628 39.45-10.168 11.615-27.835 12.794-39.45 2.627L9.544 194.604C-2.071 184.437-3.25 166.77 6.918 155.155c.873-.997 1.8-1.912 2.767-2.75l106.142-93.001c11.615-10.168 29.282-8.989 39.45 2.626 10.167 11.616 8.988 29.283-2.628 39.45l-82.27 72.086 82.27 72.086zm243.524 42.077c-11.615 10.167-29.282 8.988-39.45-2.627-10.167-11.616-8.988-29.283 2.628-39.45l82.27-72.086-82.27-72.086c-11.616-10.167-12.795-27.834-2.628-39.45 10.168-11.615 27.835-12.794 39.45-2.626l106.142 93.001a28.366 28.366 0 012.767 2.75c10.168 11.615 8.989 29.282-2.626 39.449l-106.283 93.125z" />
  </svg>,
  'Html'
);

// Function to wrap icons with common styles
const getStyledIcon = (IconComponent: any) => (
  <IconComponent fontSize="small" sx={{ transform: "scale(0.8)" }} />
);

// Mapping categories to styled MUI Icons
const categoryIcons: Record<string, JSX.Element> = {
  "Physics": getStyledIcon(ElectricBoltIcon),
  "Mathematics": getStyledIcon(FunctionsIcon),
  "Computer Science": getStyledIcon(ComputerIcon),
  "Biology": getStyledIcon(BiotechIcon),
  "Computer Vision and Graphics": getStyledIcon(PhotoCameraIcon),
  "Machine Learning": <span className="material-symbols-outlined" style={{ fontSize: "18px", transform: "scale(0.8)" }}>
    network_intel_node
  </span>,
  "Robotics and Control": getStyledIcon(PrecisionManufacturingIcon),
  "Language": getStyledIcon(GTranslateIcon),
  "Statistics": getStyledIcon(EqualizerIcon),
  "Medicine": getStyledIcon(MedicalServicesIcon),
  "Audio and Speech": getStyledIcon(VolumeUpIcon),
  "Economics": getStyledIcon(MonitorIcon),
  "Chemistry": getStyledIcon(ScienceIcon),
};

export const SCHOLAR_INBOX_PAPERS_URL = 'https://www.scholar-inbox.com/papers/';
const MAX_DETAILED_AUTHORS = 10;

export interface HighlightDetails {
  startIndex: number;
  endIndex: number;
  highlightClass: string;
}

export interface SSHighlightDetails {
  ss_startIndex: number;
  ss_endIndex: number;
  ss_alphaValue: number;
  word: string;
}

export interface AuthorDetail {
  author_id: number;
  author_name: string;
  highlighted?: boolean;
}

export interface CompactPaperProps {
  paperId?: number;
  loading?: boolean;
  title?: string;
  authors?: string;
  submittedDate?: string;
  dateVenue?: string;
  conferenceName?: string;
  relevance?: number;
  read?: boolean;
  githubLink?: string;
  arxivLink?: string;
  arxivId?: string;
  paperHTMLLink?: string;
  paperLink?: string;
  mainText?: string;
  bookmarkDate?: string;
  // rgba representation of relevance provided
  colour?: number[];
  bookmarked?: boolean;
  is_cached?: boolean;
  is_cached_filename?: string;
  twitterHype?: boolean;
  userHype?: boolean;
  rating?: number;
  hideControls?: boolean;
  failedPaper?: boolean;
  likes?: number;
  useScholarInboxPdfServer?: boolean;
  twitterShareLink?: string;
  source?: string;
  conf_id?: number;
  conference_year?: number;
  semantic_scholar_id?: string;
  user_paper_collections?: string[];
  all_user_collections?: string[];
  similarity?: number;
  similarity_color?: number[];
  titleHighlightIndexes?: number[];
  authorsHighlightIndexes?: number[];
  abstractHighlightIndexes?: number[];
  inferredHighlightStartIndex?: number;
  inferredHighlightEndIndex?: number;
  inferredHighlights?: HighlightDetails[];
  searchIn?: string[];
  teaserFigures?: TeaserFigureProps[];
  totalLikes?: number;
  totalReads?: number;
  deleteBookmarkedPaper?: (paperId: number) => void;
  deleteCollectionPaper?: (paperId: number, collectionName: string) => boolean;
  teaser_figures?: TeaserFigureProps[];
  showTeaserFigures?: boolean;
  paperIndex?: number;
  updateTeaserFiguresShown?: (idx: number) => void;
  category?: string;
  ssAbstractHighlightDetails?: SSHighlightDetails[];
  ssTitleHighlightDetails?: SSHighlightDetails[];
  hasScrolled?: boolean;
  featureToggles?: FeatureToggle[];
  collectionAdditionDate?: string;
  search_in_planner?: boolean;
  citations?: number;
  author_details?: AuthorDetail[];
  poster_tag?: string;
  is_conference_poster?: boolean;
  pinned_poster?: boolean;
  poster_id?: number;
  setPinned?: (poster_id: number) => void;
  setUnpinned?: (poster_id: number) => void;
  collapsed?: boolean;
  forceMobileView?: boolean;
  is_authenticated?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export interface ConfLinkProps {
  conference_id: number;
  conference_year: number;
}

const SmallSimilarityBadge = styled('div')(({ theme }) => ({
  // TODO: adjust to the size
  // padding: theme.spacing(0, 2),
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  // borderRadius: '0%',
  marginRight: '0px',
  marginLeft: '8px',
  marginTop: '2px',
  marginBottom: '2px',

}));


const RelevanceBadge = styled('div')(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  // marginRight: '-4px',
  marginLeft: '6px',
}));


const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
}));

export const MENDELEY_TECHNICAL_LIMITATIONS_TEXT = "Due to technical limitations with Mendeley, if you want to add another paper or your extension doesn't pick this one up - please refresh the page, click on the add button and then open the extension."

export const MendeleyTooltip = React.forwardRef(function MendeleyTooltip(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <span style={{ textDecoration: 'underline', textDecorationStyle: 'dashed', textDecorationColor: '#b2b2b2' }} {...props} ref={ref as React.RefObject<HTMLDivElement>}>
      Mendeley
    </span>
  );
});

const _processBibtexTitle = (title: string): string => {
  const words = title.split(/\s+/);
  const wrappedTitle: string[] = [];

  for (const word of words) {
    if (/\b[A-Z][a-z]*[A-Z]+\w*\b/.test(word)) {
      wrappedTitle.push(`{${word}}`);
    } else {
      wrappedTitle.push(word);
    }
  }

  return wrappedTitle.join(' ');
}

const _addSubmittedOn = (input: string): string => {
  return input.replace(/\((.*?)\)/, (match, p1) => `(Submitted on ${p1})`);
}

const _removeBrackets = (input: string): string => {
  return input.replace(/\s*\(.*?\)\s*/, '');
}

function _formatPlainTextAuthors(authors: string): string {
  // Check if the input uses the "Lastname, Firstname and ..." format
  const isLastnameFirstFormat = authors.includes(" and ");

  if (isLastnameFirstFormat) {
    // Format: "Lastname1, Firstname1 and Lastname2, Firstname2 and ..."
    const authorArray = authors.split(" and ").map((author) => {
      const [lastName, firstName] = author.split(", ").map((part) => part.trim());
      return `${firstName} ${lastName}`.trim();
    });

    // Handle single or multiple authors
    if (authorArray.length === 1) {
      return authorArray[0];
    }

    const lastAuthor = authorArray.pop();
    return `${authorArray.join(", ")} and ${lastAuthor}`;
  } else {
    // Format: "FirstName1 LastName1, FirstName2 LastName2, ..."
    const authorArray = authors.split(",").map((author) => author.trim());

    // Handle single or multiple authors
    if (authorArray.length === 1) {
      return authorArray[0];
    }

    const lastAuthor = authorArray.pop();
    return `${authorArray.join(", ")} and ${lastAuthor}`;
  }
}

function _removeLeadingZeros(input: string): string {
  const regex = /(\D+-\D+-)(0)(\d+)/;
  const match = input.match(regex);

  if (match) {
    const [, prefix, leadingZero, number] = match;
    const result = prefix + number;

    return result;
  }

  return input;
}

function _formatBibtexAuthors(authors: string): string {
  // Check if the input uses the "Lastname, Firstname and ..." format
  const isLastnameFirstFormat = authors.includes(" and ");

  if (isLastnameFirstFormat) {
    // Format: "Lastname1, Firstname1 and Lastname2, Firstname2 and ..."
    return authors
      .split(" and ")
      .map((author) => {
        const [lastName, firstName] = author.split(", ").map((part) => part.trim());
        return `${firstName} ${lastName}`.trim();
      })
      .join(" and ");
  } else {
    // Format: "FirstName1 LastName1, FirstName2 LastName2, ..."
    return authors.split(",").map((author) => author.trim()).join(" and ");
  }
}

const _orderAuthorDetails = (authors: string, authorDetails: AuthorDetail[]): AuthorDetail[] => {
  // possible formats for authors string:
  // Format 1: "Lastname1, Firstname1 and Lastname2, Firstname2 and ..."
  // Format 2: "FirstName1 LastName1, FirstName2 LastName2, ..."
  const isLastnameFirstFormat = authors.includes(" and ");

  // Parse authors string into an array of author names
  const parsedAuthors = isLastnameFirstFormat
    ? authors
      .split(" and ") // Split by "and"
      .flatMap(part => part.split(", ")) // Split by commas
      .reduce((acc, cur, idx, arr) => {
        if (idx % 2 === 0) {
          acc.push(`${arr[idx + 1]} ${cur}`); // Combine Firstname and Lastname
        }
        return acc;
      }, [] as string[])
    : authors.split(", "); // Split by commas for Format 2

  // Create a map of authorDetails by name variations
  const authorMap = new Map<string, AuthorDetail>();
  authorDetails.forEach(detail => {
    const [firstName, lastName] = detail.author_name.split(" ");
    if (firstName && lastName) {
      // Add both name variations to the map
      authorMap.set(`${firstName} ${lastName}`.toLowerCase(), detail);
      authorMap.set(`${lastName} ${firstName}`.toLowerCase(), detail);
    }
  });

  // Order authorDetails based on parsedAuthors
  const orderedAuthorDetails = parsedAuthors
    .map(name => authorMap.get(name.toLowerCase()))
    .filter(Boolean) as AuthorDetail[]; // Filter out unmatched names

  return orderedAuthorDetails;
};

// Comparator function for sorting teaser figures correctly
const compareFigures = (a: TeaserFigureProps, b: TeaserFigureProps) => {
  const aNumber = a.figureNumber || 0;
  const bNumber = b.figureNumber || 0;

  if (aNumber !== bNumber) {
    return aNumber - bNumber;
  }

  const aType = a.figureType || '';
  const bType = b.figureType || '';

  return aType.localeCompare(bType);
};

function ArxivIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"

    >
      <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M10 233 c0 -4 19 -26 42 -50 53 -54 55 -77 9 -119 -48 -43 -33 -55
19 -14 l41 33 42 -33 c70 -56 74 -56 24 -3 -32 33 -45 53 -37 58 8 5 8 10 -1
21 -10 12 -6 21 21 49 19 19 29 35 24 35 -6 0 -21 -11 -34 -25 -13 -14 -28
-25 -34 -25 -6 0 -33 18 -60 40 -50 41 -56 45 -56 33z"/>
      </g>
    </SvgIcon>
  );
}

const GithubIcon = createSvgIcon(
  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />,
  'Github',
);

/**
 * 
 * @param props PaperProps
 * @returns Paper component
 */
export default function CompactPaper(props: CompactPaperProps) {
  const onMobile = props.forceMobileView || BreakpointUtils.lessThanMedium();
  const onSmallScreen = BreakpointUtils.lessThanSmall();
  const [paperId, setPaperId] = useState<number>(props.paperId);
  const [loading, setLoading] = useState(props.loading);
  const [dateVenue, setDateVenue] = useState<string>(props.dateVenue ? props.dateVenue : '');
  const [authors, setAuthors] = useState<string>(props.authors ? props.authors : '');
  const [authorDetails, setAuthorDetails] = useState<AuthorDetail[]>(props.author_details ? _orderAuthorDetails(props.authors, props.author_details) : null);
  const [relevance, setRelevance] = useState<number>(props.relevance ? props.relevance : 0);
  const [read, setRead] = useState<boolean>(props.read ? props.read : false);
  const [upVoted, setUpVoted] = useState<boolean>(false);
  const [downVoted, setDownVoted] = useState<boolean>(false);
  const [relevanceColour, setRelevanceColour] =
    useState<number[]>(props.colour ? props.colour : [0, 0, 0, 0]);
  const [citations, setCitations] = useState<number>(props.citations ? props.citations : 0);
  const [showTeaserFigures, setShowTeaserFigures] = useState(false);
  const [showSimilarPapers, setShowSimilarPapers] = useState(false);
  const [abstract, setAbstract] = useState<string>(props.mainText ? props.mainText : '');
  const [showAbstract, setShowAbstract] = useState((props.similarity == null && !props.is_conference_poster) && !props.collapsed); // Show abstract if similarity is not available and it's not a conference poster
  const [bookmarked, setBookmarked] = useState(props.bookmarked ? props.bookmarked : false);
  const [addCollectionAnchorEl, setAddCollectionAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [collectionInput, setCollectionInput] = useState(false);
  const [collectionInputExpanded, setCollectionInputExpanded] = useState(true);
  const [collectionBlankChip, setCollectionBlankChip] = useState(false);
  const [highlightedTitle, setHighlightedTitle] = useState<JSX.Element[]>([]);
  const [highlightedAuthors, setHighlightedAuthors] = useState<JSX.Element[]>([]);
  const [highlightedAbstract, setHighlightedAbstract] = useState<JSX.Element[]>([]);
  const [highlightAbstract, setHighlightAbstract] = useState<boolean>(false);
  const [hideControls, setHideControls] = useState<boolean>(props.hideControls ? props.hideControls : false);
  const [failedPaper, setFailedPaper] = useState<boolean>(props.failedPaper ? props.failedPaper : false);
  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  const [confLinkData, setConfLinkData] =
    useState<ConfLinkProps>(props.conf_id && props.conference_year ? { conference_id: props.conf_id, conference_year: props.conference_year } : null);
  const [userPaperCollections, setUserPaperCollections] = useState<string[]>(props.user_paper_collections ? props.user_paper_collections : []);
  const [allUserCollections, setAllUserCollections] = useState<string[]>(props.all_user_collections ? props.all_user_collections : []);
  const [similarity, setSimilarity] = useState<number>(props.similarity != null ? props.similarity : -1);
  const [similarityColor, setSimilarityColor] = useState<number[]>(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);
  const [totalLikes, setTotalLikes] = useState<number>(props.totalLikes);
  const [totalReads, setTotalReads] = useState<number>(props.totalReads);
  const [teaserFigures, setTeaserFigures] = useState<TeaserFigureProps[]>(props.teaser_figures ? [...props.teaser_figures].sort(compareFigures) : []);
  const [bibtexButtonActive, setBibtexButtonActive] = useState<boolean>(false);
  const [bibtexAnchorEl, setBibtexAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isPinnedInPlanner, setIsPinnedInPlanner] = useState<boolean>(false);
  const bibtexPopoverOpen = Boolean(bibtexAnchorEl);
  const [pinned, setPinned] = useState<boolean>(props.pinned_poster);

  const enableBookmarkedButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'show_bookmark')?.is_toggled ?? false, [props.featureToggles]);
  const enableBibtexButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'show_bibtex')?.is_toggled ?? false, [props.featureToggles]);
  const enableShareOnSocialMediaButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'show_social_media_share')?.is_toggled ?? false, [props.featureToggles]);
  const enableHTMLButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'show_html')?.is_toggled ?? false, [props.featureToggles]);
  const enableMobileBookmarkedButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_show_bookmark')?.is_toggled ?? false, [props.featureToggles]);
  const enableMobileBibtexButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_show_bibtex')?.is_toggled ?? false, [props.featureToggles]);
  const enableMobileShareOnSocialMediaButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_show_social_media_share')?.is_toggled ?? false, [props.featureToggles]);
  const enableMobileHTMLButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_show_html')?.is_toggled ?? false, [props.featureToggles]);

  const isUrlAvailable = (props.is_cached && props.useScholarInboxPdfServer) || props.arxivLink;
  const isAuthenticated = props.is_authenticated ?? true;

  // FOR LOCAL TESTING ONLY / MOCKS
  // useEffect(() => {
  //   setTeaserFigures(
  //     [
  //       {
  //         imageUrl: '/image/test-teaser-figures/2-Figure1-1.png',
  //         caption: 'Figure 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget diam leo. In sed nulla vitae odio suscipit vulputate id ut justo. Nulla in tortor ac est pellentesque feugiat. Donec mollis mauris a molestie ullamcorper. Morbi id mattis ex, interdum vulputate enim. Curabitur porta convallis orci efficitur bibendum. Etiam quam velit, sagittis nec auctor ac, pulvinar vitae nisl. Morbi egestas viverra sem hendrerit posuere. Aenean sollicitudin luctus semper. Praesent id leo ipsum. Nam a felis interdum, pellentesque lorem ac, tempor urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  //       },
  //       {
  //         imageUrl: '/image/test-teaser-figures/4-Table1-1.png',
  //         caption: 'TABLE IV 123123123'
  //       },
  //       {
  //         imageUrl: '/image/test-teaser-figures/4-Figure3-1.png',
  //         caption: 'Figure 1 | Overview of SIMA. In SIMA, we collect a large and diverse dataset of gameplay from both curated research environments and commercial video games. This dataset is used to train agents to follow open-ended language instructions via pixel inputs and keyboard-and-mouse action outputs.'
  //       },
  //     ]
  //   )
  // }, []);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSimilarity(77);
  //     setSimilarityColor(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);
  //   }, 50);
  // }, []);

  useEffect(() => {
    // highlight inferred sentence if not in the search area
    const searchTitleHighlights = Array.isArray(props.titleHighlightIndexes) && props.titleHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'title');
    const searchAuthorHighlights = Array.isArray(props.authorsHighlightIndexes) && props.authorsHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'authors');
    const searchAbstractHighlights = Array.isArray(props.abstractHighlightIndexes) && props.abstractHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'abstract');

    // set highlights if there are any
    if (searchTitleHighlights && !Array.isArray(props.ssTitleHighlightDetails)) {
      const highlightedText = textToHighlightedElements(props.title, props.titleHighlightIndexes, null, null, setPaperAsRead);
      setHighlightedTitle(highlightedText);
    } else if (Array.isArray(props.ssTitleHighlightDetails) && props.ssTitleHighlightDetails.length) {
      const indexes = [];
      const alphas = [];
      props.ssTitleHighlightDetails.map(highlight => {
        indexes.push(highlight.ss_startIndex);
        indexes.push(highlight.ss_endIndex);
        alphas.push(highlight.ss_alphaValue);
      })
      const highlightedText = textToHighlightedElementsForSS(props.title, indexes, alphas);
      setHighlightedTitle(highlightedText);
    }

    if (searchAuthorHighlights) {
      if (authorDetails) {
        // there are authors with pages/links. highlight those
        const updatedAuthorDetails = setHighlightsForAuthorDetails(props.authors, authorDetails, props.authorsHighlightIndexes)
        setAuthorDetails(updatedAuthorDetails);
      } else {
        // highlight authors like we did before the author pages/links
        // shorten authors list when the number of authors is > MAX_N_AUTHORS, always keeping the last author listed
        let authors = props.authors;
        let highlightIndexes = props.authorsHighlightIndexes;

        [authors, highlightIndexes] = shortenAuthorsAndHighlightedIndices(authors, highlightIndexes);

        const highlightedText = textToHighlightedElements(authors, highlightIndexes, null, true, setPaperAsRead);

        setHighlightedAuthors(highlightedText);
      }
    } else {
      setAuthors(shortenAuthors(props.authors));
    }

    if (searchAbstractHighlights && !Array.isArray(props.ssAbstractHighlightDetails)) {
      const highlightedText = textToHighlightedElements(props.mainText, props.abstractHighlightIndexes, null, null, setPaperAsRead);
      setHighlightedAbstract(highlightedText);
    } else if (Array.isArray(props.ssAbstractHighlightDetails) && props.ssAbstractHighlightDetails.length) {
      const indexes = [];
      const alphas = [];
      props.ssAbstractHighlightDetails.map(highlight => {
        indexes.push(highlight.ss_startIndex);
        indexes.push(highlight.ss_endIndex);
        alphas.push(highlight.ss_alphaValue);
      })
      const highlightedText = textToHighlightedElementsForSS(props.mainText, indexes, alphas);
      setHighlightedAbstract(highlightedText);
    }

    if (props.inferredHighlights?.length > 0 && !searchAbstractHighlights && !Array.isArray(props.ssAbstractHighlightDetails)) {
      const indexes = [];
      const classes = [];
      props.inferredHighlights.map(highlight => {
        indexes.push(highlight.startIndex);
        indexes.push(highlight.endIndex);
        classes.push(highlight.highlightClass);
      })
      const highlightedText = textToHighlightedElements(props.mainText, indexes, classes, false, setPaperAsRead);
      setHighlightedAbstract(highlightedText);
    }

    if (props.rating === 1) setUpVoted(true);
    if (props.rating === -1) setDownVoted(true);
    if (props.bookmarked) setBookmarked(true);
    if (props.user_paper_collections) setUserPaperCollections(props.user_paper_collections);
    // console.log('collections: ', props.user_paper_collections)

    if (props.all_user_collections) setAllUserCollections(props.all_user_collections);

  }, [props.ssTitleHighlightDetails, props.ssAbstractHighlightDetails, props.titleHighlightIndexes, props.authorsHighlightIndexes, props.abstractHighlightIndexes, props.searchIn, props.inferredHighlights]);

  useEffect(() => {
    if (props.search_in_planner) {
      const req = GetPaperPinnedStatusForPlanner(props.paperId?.toString());
      req.then(resp => {
        if (resp.success) {
          setIsPinnedInPlanner(resp.pinned);
        }
      })
    }
    if (props.is_conference_poster) {
      const req = GetPosterDetails(paperId?.toString());
      req.then((res: PosterDetailsResponse) => {
        if (res.success) {
          setAbstract(res.abstract);
          setTotalLikes(res.total_likes);
          setTotalReads(res.total_read);
          setCitations(res.citations);
          setRead(res.read);
          setShowAbstract(false);
        }
      })
    }

    // mark paper as read if this paper has been opened via email link ('paper' + props.paperId.toString() is in the hash)
    const hash = window.location.hash;
    if (hash.includes('paper' + props.paperId.toString())) {
      setPaperAsRead();
    }
  }, []);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setRelevance(props.relevance ? props.relevance : 0);
  }, [props.relevance]);

  useEffect(() => {
    setRelevanceColour(props.colour ? props.colour : [0, 0, 0, 0]);
  }, [props.colour]);

  useEffect(() => {
    // shorten authors if they exceed 2 lines (>16 authors)
    setAuthors(props.authors ? shortenAuthors(props.authors) : '');
  }, [props.authors]);

  useEffect(() => {
    setUserPaperCollections(props.user_paper_collections ? props.user_paper_collections : []);
  }, [props.user_paper_collections]);

  useEffect(() => {
    setAllUserCollections(props.all_user_collections ? props.all_user_collections : []);
  }, [props.all_user_collections]);

  useEffect(() => {
    setShowTeaserFigures(props.showTeaserFigures);
  }, [props.showTeaserFigures]);

  useEffect(() => {
    setHighlightAbstract(highlightedAbstract.length > 0);
  }, [highlightedAbstract]);

  const handleThumbsUpClick = () => {
    const rating = upVoted ? 0 : 1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(!upVoted);
    setDownVoted(false);
    setPaperAsRead();
    setTotalLikes(totalLikes + (rating === 1 ? 1 : -1));

    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  const handleThumbsDownClick = () => {
    const rating = downVoted ? 0 : -1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(false);
    setDownVoted(!downVoted);
    setPaperAsRead();
    setTotalLikes(totalLikes + (upVoted ? -1 : 0));

    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  const handleCollectionPopoverClose = () => {
    setAddCollectionAnchorEl(null);
    setCollectionInput(false);
  };

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link)
    setCopiedSnackbarOpen(true);
  };

  const handleExpandTeaserFigures = (paperId: string) => {
    // scroll to top of this paper if not expanded
    if (!showTeaserFigures) {
      // if on mobile - scroll to teaser figure itself, else scroll to top of the paper component
      if (onSmallScreen) {
        const paperHeight = document.getElementById(paperId).offsetHeight;
        setTimeout(() => {
          scrollTo(paperId, -130 + (paperHeight - 35), 'smooth');
        }, 10);
      } else {
        setTimeout(() => {
          scrollTo(paperId, -130, 'smooth');
        }, 10);
      }
    }
    setShowTeaserFigures(!showTeaserFigures);
    if (showSimilarPapers) {
      setShowSimilarPapers(false);
    }

    if (!showTeaserFigures && !read) {
      setPaperAsRead();
    }
    props.updateTeaserFiguresShown(props.paperIndex);

    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  const handleShowSimilarPapers = () => {
    // scroll to top of this paper if not expanded
    if (!showSimilarPapers && !onMobile) {
      setTimeout(() => {
        scrollTo('paper' + (props.paperId ? props.paperId.toString() : 'NaN'), -130, 'smooth');
      }, 10);
    }

    setShowSimilarPapers(!showSimilarPapers);
    if (showTeaserFigures) {
      setShowTeaserFigures(false);
    }

    if (!showSimilarPapers && !read) {
      setPaperAsRead();
    }

    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  let similarPapersBox = showSimilarPapers ? (
    <SimilarPapers paperId={paperId}
      allUserCollections={allUserCollections}></SimilarPapers>
  )
    : <></>;

  const handleHideAbstract = () => {
    setShowAbstract(false);
    setShowSimilarPapers(false);
    setShowTeaserFigures(false);
  }

  const handleShowAbstract = () => {
    if (showAbstract) {
      setShowSimilarPapers(false);
      setShowTeaserFigures(false);
    }
    setShowAbstract(!showAbstract);
  }

  const handleBookmarkButtonClick = () => {
    if (bookmarked) {
      if (props.deleteBookmarkedPaper)
        props.deleteBookmarkedPaper(paperId);
      MarkPaperAsBookmarked(false, paperId?.toString());
    } else {
      MarkPaperAsBookmarked(true, paperId?.toString());
    }
    setBookmarked(!bookmarked);
    setPaperAsRead();
    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  };

  const setPaperAsRead = () => {
    MarkPaperAsRead(props.paperId?.toString());
    setRead(true);
    setTotalReads(totalReads + (read ? 0 : 1));
    // set as interacted to display the survey
    ls.set('survey-interacted', true, { ttl: 2592000 }); // 30 days ttl
  }

  const handleBibtexPopoverClose = () => {
    setBibtexAnchorEl(null);
    setBibtexButtonActive(false);
  };

  const handleCollectionButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCollectionInput(!collectionInput);
    setAddCollectionAnchorEl(event.currentTarget);
    setCollectionInputExpanded(!collectionInput);
    setPaperAsRead();
  };

  const handleCollectionBlankChip = () => {
    setCollectionBlankChip(!collectionBlankChip);
    // setPaperAsRead();
  };

  const handleCollectionSelect = (event: any, newCollection: string | null) => {
    if (newCollection) {
      if (!(userPaperCollections.includes(newCollection))) {
        userPaperCollections.push(newCollection);
        setUserPaperCollections(userPaperCollections);
        AddPaperCollection(newCollection, paperId?.toString());
      }
      if (!(allUserCollections.includes(newCollection))) {
        allUserCollections.push(newCollection);
        allUserCollections.sort((a, b) => a.localeCompare(b));
        setAllUserCollections(allUserCollections);
      }
      handleCollectionButtonClick(event);
    }
  };

  const handleChipDelete = (chipToDelete: string | null) => {
    console.log('Poooh', chipToDelete)
    // delete collection from paperCollections Array
    const index = userPaperCollections.indexOf(chipToDelete, 0);
    if (index > -1) { userPaperCollections.splice(index, 1); }
    setUserPaperCollections(userPaperCollections);
    RemovePaperCollection(chipToDelete, paperId?.toString());
    if (props.deleteCollectionPaper) {
      const reloadAnotherCollection = props.deleteCollectionPaper(paperId, chipToDelete);
      if (reloadAnotherCollection) {
        UpdateCollectionTab("remove_collection", chipToDelete, paperId.toString(), false);
      }
    }
    // handleCollectionButtonClick();
    handleCollectionBlankChip();
  };

  const handleTogglePinForPlanner = () => {
    if (isPinnedInPlanner) {
      MarkPaperAsPinnedForPlanner(false, paperId?.toString());
    } else {
      MarkPaperAsPinnedForPlanner(true, paperId?.toString());
    }
    setIsPinnedInPlanner(!isPinnedInPlanner);
    setPaperAsRead();
  };

  const handlePinnedClick = () => {
    if (pinned) {
      MarkPosterAsPinned(false, props.poster_id?.toString());
      props.setUnpinned(props.poster_id);
    } else {
      MarkPosterAsPinned(true, props.poster_id?.toString());
      props.setPinned(props.poster_id);
    }
    setPinned(!pinned);
  };

  const thumbsUpButton =
    <Tooltip arrow title='Mark this paper as "relevant" for your recommender' placement="top">
      <IconButton onClick={handleThumbsUpClick}>
        {upVoted ? <ThumbUpAltIcon color='secondary' /> :
          <ThumbUpOffAltIcon
            sx={{
              color: "action.active",
              "&:hover": { color: "secondary.main" }
            }}
          />
        }
      </IconButton>
    </Tooltip>;
  const thumbsDownButton =
    <Tooltip arrow title='Mark this paper as "not relevant" for your recommender' placement="top">
      <IconButton onClick={handleThumbsDownClick}>
        {downVoted ? <ThumbDownIcon color='secondary' /> :
          <ThumbDownOffAltIcon
            sx={{
              color: "action.active",
              "&:hover": { color: "secondary.main" }
            }}
          />
        }
      </IconButton>
    </Tooltip>;

  const bookmarkButton = ((onMobile && (enableMobileBookmarkedButton === undefined || enableMobileBookmarkedButton)) || (!onMobile && (enableBookmarkedButton === undefined || enableBookmarkedButton))) ?
    <Tooltip arrow title='Bookmark' placement="top">{
      (bookmarked ? (
        <IconButton onClick={handleBookmarkButtonClick}>
          <BookmarkAddedIcon color='secondary' />
        </IconButton>
      ) : (
        <IconButton onClick={handleBookmarkButtonClick}>
          <BookmarkAddOutlinedIcon
            sx={{
              color: "action.active",
              "&:hover": { color: "secondary.main" }
            }} />
        </IconButton>
      ))}
    </Tooltip> : <></>;

  const collectionButton = (true) ?
    (<Tooltip arrow title='Add paper to the collection' placement="top">
      <IconButton onClick={handleCollectionButtonClick} >
        <AddIcon
          sx={{
            color: "action.active",
            "&:hover": { color: "secondary.main" }
          }}
        />
      </IconButton>
    </Tooltip>) : <></>;

  const chipCollections = userPaperCollections ? userPaperCollections?.map((collection) => (
    <Chip
      label={collection}
      key={collection + 'chip'}
      onDelete={() => handleChipDelete(collection)}
      sx={{
        height: '20px',
        px: '3px',
        pr: '5px',
        ml: '5px',
        my: '1px',
        borderRadius: '6px',
        '& .MuiChip-label': { p: 0, pl: '4px', pr: '5px', my: '-1px' },
        '& .MuiChip-deleteIcon': { mr: '-2px' }
      }}
      size='small'
    />
  )) : <></>;

  const collectionAutocomplete = collectionInput ?
    <Box sx={{ width: 140, color: 'grey', mt: -0.5, position: { md: 'relative', lg: 'absolute', xs: 'relative' }, left: { md: '', lg: '-140px', xs: '' } }}>
      <Autocomplete
        id={props.paperId && ("collection-autocomplete" + props.paperId.toString())}
        sx={{ inputFocused: 'true', mt: '-12px', height: 40 }}
        open={collectionInputExpanded}
        onOpen={() => setCollectionInputExpanded(true)}
        onClose={() => setCollectionInputExpanded(false)}
        value={""}
        freeSolo
        blurOnSelect
        clearOnBlur
        selectOnFocus
        disableListWrap
        disableClearable
        renderInput={(params) => (
          <Tooltip arrow title='Enter a new name to create a new collection' placement="top">
            <TextField
              autoFocus
              {...params}
              color='secondary'
              inputProps={{ ...params.inputProps, maxLength: 30 }}
              variant="standard"
              placeholder="Add to collection"
            />
          </Tooltip>
        )}
        options={allUserCollections}
        onChange={handleCollectionSelect}
      />
    </Box>
    : <></>;

  const githubLinkButton = props.githubLink ?
    <Tooltip arrow title='View project page/github for this paper' placement="top">
      <IconButton sx={{
        width: '1.4em', height: '1.4em',
      }} onClick={setPaperAsRead}>
        <Link
          href={props.githubLink}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <GithubIcon sx={{
            mt: 1, transform: 'scale(0.9)', color: "action.active",
            "&:hover": { color: "secondary.main" }
          }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;

  const arxivLinkButton = props.arxivId ?
    <Tooltip arrow placement="top" title='View arXiv page for this paper'>
      <IconButton sx={{
        width: '1.4em', height: '1.4em', display: "flex",
        color: 'action.active',
        "&:hover": { color: 'secondary.main' }
      }} onClick={failedPaper ? null : setPaperAsRead}>
        <Link
          href={'https://arxiv.org/abs/' + props.arxivId}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ArxivIcon sx={{
            mt: 1, transform: 'scale(0.9)', color: 'inherit',
          }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;

  const paperHTMLLinkButton = (((onMobile && (enableMobileHTMLButton === undefined || enableMobileHTMLButton)) || (!onMobile && (enableHTMLButton === undefined || enableHTMLButton))) && props.paperHTMLLink) ?
    <Tooltip arrow title='View HTML page for this paper' placement="top">
      <IconButton sx={{
        width: '1.4em', height: '1.4em',
      }} onClick={setPaperAsRead}>
        <Link
          href={props.paperHTMLLink}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <HtmlIcon sx={{
            mt: 1, transform: 'scale(0.9)', color: "action.active",
            "&:hover": { color: "secondary.main" }
          }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;

  const shareMenu = isUrlAvailable ?
    <ShareMenu
      title={props.title}
      authors={props.authors}
      mainText={props.mainText}
      arxivLink={props.arxivLink}
      is_cached={props.is_cached}
      is_cached_filename={props.is_cached_filename}
      useScholarInboxPdfServer={props.useScholarInboxPdfServer}
      conferenceName={props.conferenceName}
      conference_year={props.conference_year}
      submittedDate={props.submittedDate}
      dateVenue={dateVenue}
      enableBibtex={onMobile ?
        (enableMobileBibtexButton === undefined || enableMobileBibtexButton) :
        (enableBibtexButton === undefined || enableBibtexButton)}
      enableMobileBibtexButton={enableMobileBibtexButton === undefined || enableMobileBibtexButton}
      enableBibtexButton={enableBibtexButton === undefined || enableBibtexButton}
      enableMobileShareOnSocialMediaButton={enableMobileShareOnSocialMediaButton === undefined || enableMobileShareOnSocialMediaButton}
      enableShareOnSocialMediaButton={enableShareOnSocialMediaButton === undefined || enableShareOnSocialMediaButton}
    />
    : <></>;

  let pinnedIcon = props.pinned_poster ? <PushPinIcon sx={{ transform: 'scale(0.65)', }} /> : <PushPinOutlinedIcon sx={{ transform: 'scale(0.65)', }} />;

  return (
    <Box id={props.paperId && ('paper' + props.paperId.toString())} sx={{ backgroundColor: 'white', borderRadius: '7px', mx: '2px', my: '8px' }}>
      <Box sx={{
        backgroundColor: colourListToColour(relevanceColour),
        boxShadow: '0 0 10px #ccc',
        borderRadius: '7px',
        my: '10px',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '3px solid transparent', // Always keep the border
        borderLeftColor: (hideControls || failedPaper || read || loading) ? 'transparent' : '#f44336!important',
        // lowering opacity for papers that are not scrolled to on initial digest load, don't change for similar papers
        opacity: (!props.similarity && !props.hasScrolled && window.location.hash && window.location.hash.startsWith('#paper') && window.location.hash !== '#paper' + props.paperId) ? 0.35 : 1,
        mb: (!props.hasScrolled && window.location.hash && window.location.hash === '#paper' + props.paperId) ? '50px' : (showAbstract ? '12px' : '8px'),
        mt: (!props.hasScrolled && window.location.hash && window.location.hash === '#paper' + props.paperId) ? '110px' : '8px',
        transition: 'opacity 1s ease-in-out, margin 1s ease-in-out, background-color 1s ease-in-out', // Exclude border-left-color
      }}>
        {/* DESKTOP VIEW */}
        <Box sx={{
          display: { md: 'none', lg: 'flex', xs: 'none' },
          flexDirection: 'row',
        }}>
          {/* title, authors, venue and stats */}
          <Box sx={{
            fontSize: '14px',
            display: { md: 'none', lg: 'flex', xs: 'none' },
            flexDirection: 'column',
            paddingTop: '1px',
            minHeight: '28px',
          }}>
            {/* top row: pinned/poster tag/similarity badge/title */}
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {props.is_conference_poster && (
                <Box>
                  <IconButton onClick={(e) => { e.stopPropagation(); handlePinnedClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px', mr: '5px' }}>
                    {pinnedIcon}
                  </IconButton>
                </Box>
              )}
              {props.poster_tag && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, my: '2px' }}>
                  <Box sx={{
                    backgroundColor: 'white',
                    borderRadius: 2,
                    border: '1px solid',
                    px: 1,
                    mr: '1px',
                    width: '135px',
                    py: '2px',
                    lineHeight: '1',
                    textAlign: 'center',
                  }}>
                    {_removeLeadingZeros(props.poster_tag.replace('#', '-'))}
                  </Box>
                </Box>
              )}
              <Box
                sx={{ ml: 1, my: '2px', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'auto', }}
                onClick={props.failedPaper ? null : setPaperAsRead}
              >
                <Link
                  href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                  target={isUrlAvailable ? '_blank' : undefined}
                  sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none' }}
                >
                  <Typography variant="subtitle1" fontSize={'18px'}
                    sx={{
                      lineHeight: '1.15',
                      fontWeight: 'bold',
                    }}
                  >
                    {
                      highlightedTitle.length ? highlightedTitle.map(chunk => chunk) : props.title
                    }

                  </Typography>
                </Link>
              </Box>
            </Box>

            {/* authors */}
            <Box sx={{ ml: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'auto' }}>
              {
                !onMobile && authorDetails && authorDetails.length > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                    {
                      authorDetails.map((author, idx) => (
                        <>
                          {/* weird index hacks to contain the authors below the 10 threshold */}
                          {/* display the first N authors */}
                          {idx < MAX_DETAILED_AUTHORS && (
                            <>
                              <Link href={'/author/' + author.author_id} sx={{ color: 'black', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                                <Typography variant='body1' sx={{ background: author.highlighted ? PRIMARY_HIGHLIGHT_COLOUR : '' }}>
                                  {author.author_name}
                                </Typography>
                              </Link>
                              {idx !== MAX_DETAILED_AUTHORS - 1 && idx < authorDetails.length - 1 && (
                                <Typography variant='body1'>,&nbsp;</Typography>
                              )}
                            </>
                          )}
                          {/* display ... if we have more than 10 authors */}
                          {authorDetails.length > MAX_DETAILED_AUTHORS && idx === MAX_DETAILED_AUTHORS && (
                            <Typography variant='body1'>&nbsp;...&nbsp;</Typography>
                          )}
                          {/* display last author */}
                          {authorDetails.length > MAX_DETAILED_AUTHORS && idx === authorDetails.length - 1 && (
                            <Link href={'/author/' + author.author_id} sx={{ color: 'black', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                              <Typography variant='body1' sx={{ background: author.highlighted ? PRIMARY_HIGHLIGHT_COLOUR : '' }}>
                                {author.author_name}
                              </Typography>
                            </Link>
                          )}
                        </>
                      ))
                    }
                  </Box>
                ) : (
                  <Link onClick={props.failedPaper ? null : setPaperAsRead}
                    href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                    target={'_blank'}
                    sx={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Typography variant='body1' sx={{ display: hideControls ? 'flex' : { xs: 'none', md: 'flex' }, flexWrap: 'wrap' }}>
                      {
                        props.searchIn && props.searchIn.some(e => e === 'authors') && highlightedAuthors.length > 0 ? highlightedAuthors.map(chunk => chunk) : authors
                      }
                    </Typography>
                  </Link>
                )
              }
            </Box>

            {/* bottom row: venue, date, stats */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: '14px',
              // height: '26px',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Box sx={{ ml: 1 }}>
                {dateVenue && confLinkData ? (
                  <Link href={'/conference-papers?conference_id=' + confLinkData.conference_id + '&year=' + confLinkData.conference_year} sx={{ color: 'black' }}>{dateVenue}</Link>
                ) : dateVenue ? (_addSubmittedOn(dateVenue)) : ('')}
              </Box>
              {hideControls ? '' : (
                <Chip
                  size="small"
                  sx={{
                    border: '0px',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '20px',
                    px: '2px',
                    pr: '4px',
                    ml: '5px',
                    '& .MuiChip-label': { p: 0, my: '-1px' }, // Remove internal label padding
                  }}
                  label={
                    <Stack direction="row" spacing={0.4} alignItems="center">
                      {similarity >= 0 && (
                        <>
                          <Tooltip arrow placement="top" title="Paper Similarity">
                            <MyLocationIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                          </Tooltip>
                          <span>{similarity}</span>
                        </>
                      )}
                      {isAuthenticated && (
                        <>
                          <Tooltip arrow placement="top" title="Paper Relevance">
                            <BarChartIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                          </Tooltip>
                          <Tooltip arrow placement="top" title="Paper Relevance">
                            <span
                            // style={{
                            //   display: "flex", // Use flex to center content properly
                            //   justifyContent: "center",
                            //   alignItems: "center",
                            //   width: "22px",
                            //   height: "22px",
                            //   borderRadius: "50%",
                            //   border: "1px solid red",
                            //   backgroundColor: "rgba(255, 0, 0, 0.25)", // Transparent red
                            //   fontSize: "12px", // Adjust font size to match other numbers
                            //   fontWeight: 500, // Optional for better visibility
                            // }}
                            >
                              {roundRelevance(relevance)}
                            </span>
                          </Tooltip>
                        </>
                      )}
                      <Tooltip arrow placement="top" title={"Relevant to " + totalLikes + " users"}>
                        <ThumbUpAltIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                      </Tooltip>
                      <span>{totalLikes}</span>
                      <Tooltip arrow placement="top" title={"Read by " + totalReads + " users"}>
                        <AutoStoriesIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                      </Tooltip>
                      <span>{totalReads}</span>
                      {citations > 0 && (
                        <>
                          <Tooltip arrow placement="top" title={"Cited by " + citations + " papers"}>
                            <FormatQuoteIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                          </Tooltip>
                          <span>{citations}</span>
                        </>
                      )}
                    </Stack>
                  }
                />
              )}
              {props.category ? (
                <Chip size="small"
                  sx={{
                    border: '0px',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '20px',
                    px: '2px',
                    pr: '5px',
                    ml: '5px',
                    // mb: '3px',
                    '& .MuiChip-label': { p: 0, my: '-1px' }, // Remove internal label padding
                  }}
                  label={
                    <Stack direction="row" spacing={0.4} alignItems="center">
                      {categoryIcons[props.category] || null} {/* Display icon if available */}
                      <span>{props.category}</span>
                    </Stack>
                  }
                />
              ) : ''}
              <Box sx={{
                display: 'flex', flexWrap: 'auto', flexDirection: 'row',
                gap: 0.1, justifyItems: 'flex-end',
              }}>
                {chipCollections}
              </Box>
            </Box>
          </Box>

          {/* buttons */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 'auto',
          }}>
            {/* top row of buttons */}
            {isAuthenticated && (
              <Box sx={{ display: 'flex', flexDirection: onMobile ? 'column' : 'row', justifyContent: 'space-between', marginLeft: 'auto', transform: 'scale(0.9)', mr: '-20px' }}>
                {/* old collections spot */}
                <Box sx={{
                  marginLeft: 'auto', pr: '7px', display: 'flex', alignItems: 'center', position: 'relative', marginRight: '5.5px',
                }}>
                  {thumbsUpButton}
                  {thumbsDownButton}
                  {bookmarkButton}
                  {collectionButton}
                  {githubLinkButton}
                  {arxivLinkButton}
                  {paperHTMLLinkButton}
                  {shareMenu}
                </Box>
              </Box>
            )}

            {/* buttom buttons (just abstract one) */}
            <Box sx={{ ml: 'auto', transform: 'scale(0.9)', mt: '-4px' }}>
              {!hideControls && (
                <>
                  <ExpandMore
                    expand={showAbstract}
                    onClick={handleShowAbstract}
                    aria-expanded={showSimilarPapers}
                    aria-label="show abstract"
                  >
                    {!showAbstract ? (<Tooltip arrow placement="top" title="Show abstract"><ArrowDropDownOutlinedIcon sx={{ transform: 'scale(1.5)' }} /></Tooltip>) : (
                      <Tooltip arrow placement="top" title="Hide abstract"><ArrowDropUpOutlinedIcon sx={{ transform: 'scale(1.5) rotate(180deg)' }} /></Tooltip>)}
                  </ExpandMore>
                </>
              )}
            </Box>
          </Box>
        </Box>

        {/* MOBILE VIEW */}
        <Box sx={{
          fontSize: '14px',
          display: { md: 'flex', lg: 'none', xs: 'flex' },
          pt: '1px',
          flexDirection: 'column',
          // alignItems: 'center',
        }} >
          {/* tag + controls */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '32px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginLeft: 'auto', flexGrow: 1, overflowX: 'auto', }}>
              {isAuthenticated && (
                <Box sx={{
                  display: 'flex', alignItems: 'center',
                  width: onSmallScreen ? '90vw' : 'auto', // for overflow-x scrolling
                  overflowX: onSmallScreen ? 'scroll' : 'hidden',
                  overflowY: 'hidden',
                  maxWidth: '100%',
                  gap: '4px',
                  transform: 'scale(0.9)',
                  ml: -1,
                }}>
                  <Box sx={{ mx: '-4px' }}>
                    {thumbsUpButton}
                  </Box>
                  <Box sx={{ mx: '-4px' }}>
                    {thumbsDownButton}
                  </Box>
                  <Box sx={{ mx: '-4px' }}>
                    {bookmarkButton}
                  </Box>
                  <Box sx={{ mx: '-4px' }}>
                    {collectionButton}
                  </Box>
                  {props.githubLink && (
                    <Box sx={{ mx: '-4px' }}>
                      {githubLinkButton}
                    </Box>
                  )}
                  {props.arxivId && (
                    <Box sx={{ mx: '-4px' }}>
                      {arxivLinkButton}
                    </Box>
                  )}
                  {props.paperHTMLLink && (
                    <Box sx={{ mx: '-4px' }}>
                      {paperHTMLLinkButton}
                    </Box>
                  )}
                  <Box sx={{ mx: '-4px' }}>
                    {shareMenu}
                  </Box>
                </Box>
              )}

              {/* Right-aligned ExpandMore container */}
              <Box sx={{ 
                marginLeft: 'auto', 
                flexShrink: 0,
                transform: 'scale(0.9)',
                display: 'flex',
                alignItems: 'center'
              }}>
                {!hideControls && (
                  <ExpandMore
                    expand={showAbstract}
                    onClick={handleShowAbstract}
                    aria-expanded={showSimilarPapers}
                    aria-label="show abstract"
                  >
                    {!showAbstract ? (
                      <Tooltip title="Show abstract">
                        <ArrowDropDownOutlinedIcon sx={{ transform: 'scale(1.5)' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Hide abstract">
                        <ArrowDropUpOutlinedIcon sx={{ transform: 'scale(1.5) rotate(180deg)' }} />
                      </Tooltip>
                    )}
                  </ExpandMore>
                )}
             </Box>
            </Box>
          </Box>
          <Box
            sx={{ ml: 1, my: '2px', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'auto', }}
            onClick={props.failedPaper ? null : setPaperAsRead}
          >

            <Typography variant="subtitle1" sx={{ lineHeight: '1.2', fontSize: '1rem' }}>
              <Link
                href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                target={isUrlAvailable ? '_blank' : undefined}
                sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none', fontWeight: 'bold', }}
              >
                {
                  highlightedTitle.length ? highlightedTitle.map(chunk => chunk) : props.title
                }
              </Link>
              &nbsp;|&nbsp;
              {
                authorDetails ? (
                  <span>
                    {
                      authorDetails.map((author, idx) => (
                        <>
                          {/* weird index hacks to contain the authors below the 10 threshold */}
                          {/* display the first N authors */}
                          {idx < MAX_DETAILED_AUTHORS && (
                            <>
                              <Link href={'/author/' + author.author_id} sx={{ color: 'black', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                                <span style={{ background: author.highlighted ? PRIMARY_HIGHLIGHT_COLOUR : '', lineHeight: '1.1' }}>
                                  {author.author_name}
                                </span>
                              </Link>
                              {idx !== MAX_DETAILED_AUTHORS - 1 && idx < authorDetails.length - 1 && (
                                <span style={{ lineHeight: '1.1' }}>,&nbsp;</span>
                              )}
                            </>
                          )}
                          {/* display ... if we have more than 10 authors */}
                          {authorDetails.length > MAX_DETAILED_AUTHORS && idx === MAX_DETAILED_AUTHORS && (
                            <span style={{ lineHeight: '1.1' }}>&nbsp;...&nbsp;</span>
                          )}
                          {/* display last author */}
                          {authorDetails.length > MAX_DETAILED_AUTHORS && idx === authorDetails.length - 1 && (
                            <Link href={'/author/' + author.author_id} sx={{ color: 'black', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                              <span style={{ background: author.highlighted ? PRIMARY_HIGHLIGHT_COLOUR : '', lineHeight: '1.1' }}>
                                {author.author_name}
                              </span>
                            </Link>
                          )}
                        </>
                      ))
                    }
                  </span>
                ) : (
                  <Link onClick={props.failedPaper ? null : setPaperAsRead}
                    href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                    target={isUrlAvailable ? '_blank' : undefined}
                    sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none' }}
                  >
                    <span>
                      {
                        props.searchIn && props.searchIn.some(e => e === 'authors') && highlightedAuthors.length > 0 ? highlightedAuthors.map(chunk => chunk) : authors
                      }
                    </span>
                  </Link>
                )
              }
            </Typography>

          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
            height: 'auto',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}>
            <Box sx={{ ml: 1, lineHeight: '0.9' }}>
              {dateVenue && confLinkData ? (
                <Link href={'/conference-papers?conference_id=' + confLinkData.conference_id + '&year=' + confLinkData.conference_year} sx={{ color: 'black' }}>
                  {dateVenue}
                </Link>
              ) : dateVenue ? _removeBrackets(dateVenue) : ''}
            </Box>

            {/* Wrap stats, category, and ExpandMore together */}
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',  // Ensures everything moves to the next line together
              alignItems: 'center',
            }}>
              {!hideControls && (
                <Chip
                  size="small"
                  sx={{
                    border: '0px',
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'row',
                    height: '20px',
                    px: '3px',
                    pr: '4px',
                    ml: '5px',
                    mb: '3px',
                    '& .MuiChip-label': { p: 0 }, // Remove internal label padding
                  }}
                  label={
                    <Stack direction="row" spacing={0.4} alignItems="center">
                      {similarity >= 0 && (
                        <>
                          <Tooltip arrow placement="top" title="Paper Similarity">
                            <MyLocationIcon color="secondary" fontSize="small" sx={{ transform: 'scale(0.8)' }} />
                          </Tooltip>
                          <span>{similarity}</span>
                        </>
                      )}
                      {isAuthenticated && (
                        <>
                          <Tooltip arrow placement="top" title="Paper Relevance">
                            <BarChartIcon color="secondary" fontSize="small" />
                          </Tooltip>
                          <span>{roundRelevance(relevance)}</span>
                        </>
                      )}
                      <Tooltip arrow placement="top" title={"Relevant to " + totalLikes + " users"}>
                        <ThumbUpAltIcon color="secondary" fontSize="small" />
                      </Tooltip>
                      <span>{totalLikes}</span>
                      <Tooltip arrow placement="top" title={"Read by " + totalReads + " users"}>
                        <AutoStoriesIcon color="secondary" fontSize="small" />
                      </Tooltip>
                      <span>{totalReads}</span>
                      {citations > 0 && (
                        <>
                          <Tooltip arrow placement="top" title={"Cited by " + citations + " papers"}>
                            <FormatQuoteIcon color="secondary" fontSize="small" />
                          </Tooltip>
                          <span>{citations}</span>
                        </>
                      )}
                    </Stack>
                  }
                />
              )}

              {props.category && (
                <Chip
                  size="small"
                  sx={{
                    border: '0px',
                    borderRadius: '6px',
                    display: 'inline-flex',
                    flexDirection: 'row',
                    height: '20px',
                    px: '3px',
                    ml: '5px',
                    mb: '3px',
                    width: 'auto',
                    '& .MuiChip-label': { p: 0 }, // Remove internal label padding
                  }}
                  label={<Stack direction="row" spacing={0.4} alignItems="center">{categoryIcons[props.category] || null}</Stack>}
                />
              )}
            </Box>
          </Box>

          <Box sx={{
            display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
            gap: 0.1, justifyItems: 'flex-end', mb: '2px',
          }}>
            {chipCollections}
          </Box>
        </Box>

        <Collapse in={showAbstract}>
          <Box
            sx={{
              px: '5px',
              position: 'relative', // Allows absolute positioning of buttons
              background: 'white',
              borderRadius: (showSimilarPapers || showTeaserFigures) ? 0 : '0px 0px 7px 7px',
              ...(hideControls || failedPaper || read || loading) && {
                ml: '-3px',
                width: 'calc(100% + 3px)',
                pl: '8px',
              }
            }}
          >
            <Typography
              variant="body1"
              sx={{
                p: '3px',
                fontSize: '0.875rem',
                wordBreak: 'break-word', // Ensures words wrap early
                paddingBottom: '12px', // Prevents button overlap
              }}
            >
              <PaperAbstractComponent
                highlightAbstract={highlightAbstract}
                highlightChunks={highlightedAbstract}
                mainText={props.mainText ? props.mainText : abstract}
                setPaperAsRead={setPaperAsRead}
              />
            </Typography>

            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'scale(0.9)',
                display: 'flex',
                minHeight: '28px', // Ensures space for buttons
              }}
            >
              {!hideControls && isAuthenticated && (
                <ExpandMore
                  expand={showSimilarPapers}
                  onClick={handleShowSimilarPapers}
                  aria-expanded={showSimilarPapers}
                  aria-label="show similar papers"
                >
                  {!showSimilarPapers ? (
                    <Tooltip arrow placement="top" title="Show similar papers"><MyLocationIcon /></Tooltip> // prev. PhotoFilterIcon if we need to restore
                  ) : (
                    <Tooltip arrow placement="top" title="Hide similar papers"><LayersClearIcon /></Tooltip>
                  )}
                </ExpandMore>
              )}
              {teaserFigures.length !== 0 && isAuthenticated && (
                <ExpandMore
                  expand={showTeaserFigures}
                  onClick={() => handleExpandTeaserFigures('paper' + (props.paperId ? props.paperId.toString() : 'NaN'))}
                  aria-expanded={showTeaserFigures}
                  aria-label="show more"
                >
                  {!showTeaserFigures ? (
                    <Tooltip arrow placement="top" title="Show teaser figures"><ImageSearchIcon /></Tooltip>
                  ) : (
                    <Tooltip arrow placement="top" title="Hide teaser figures"><HideImageIcon /></Tooltip>
                  )}
                </ExpandMore>
              )}
            </Box>
          </Box>
        </Collapse>


        <Collapse in={showSimilarPapers} unmountOnExit timeout={{ enter: duration.standard, exit: 0 }}>
          <CardContent sx={{
            background: 'white', borderRadius: '0px 0px 7px 7px',
            ...(hideControls || failedPaper || read || loading) && {
              ml: '-3px',
              width: 'calc(100% + 3px)',
            }
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', }}>
              {similarPapersBox}
            </Box>
          </CardContent>
        </Collapse>
        {teaserFigures.length !== 0 && (
          <Collapse in={showTeaserFigures} unmountOnExit timeout={{ enter: duration.standard, exit: 0 }}>
            <CardContent sx={{
              background: 'white', borderRadius: '0px 0px 7px 7px',
              ...(hideControls || failedPaper || read || loading) && {
                ml: '-3px',
                width: 'calc(100% + 3px)',
              }
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                {teaserFigures.map(teaserFigure => <TeaserFigure {...teaserFigure} />)}
              </Box>
            </CardContent>
          </Collapse>
        )}
        <Popover
          id={props.title + '-collection-popover'}
          open={collectionInput}
          anchorEl={addCollectionAnchorEl}
          onClose={handleCollectionPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            maxWidth: '90%',
            overflow: 'visible',
          }}
        >
          <Box
            sx={{
              p: 1.5,
              maxHeight: '350px',
              height: allUserCollections.length * 27 + 58,
            }}
          >
            <Autocomplete
              id={props.paperId && ("collection-autocomplete" + props.paperId.toString())}
              sx={{ height: 40, width: '200px' }}
              open={collectionInputExpanded}
              onOpen={() => setCollectionInputExpanded(true)}
              // onClose={() => setCollectionInputExpanded(false)}
              value={""}
              freeSolo
              blurOnSelect
              clearOnBlur
              selectOnFocus
              disableListWrap
              disableClearable
              disablePortal={true}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  placement="bottom-start"
                  sx={{
                    zIndex: 1300,
                    width: 'fit-content',
                  }}
                />
              )}
              // override Paper to remove box shadow in the dropdown
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    boxShadow: 'none',
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  mt: 1,
                  padding: 0,
                  fontSize: '0.875rem',
                  '& .MuiAutocomplete-option': {
                    minHeight: '18px',
                    padding: '3px 6px',
                  },
                  maxHeight: '285px',
                  overflowY: 'auto',
                },
              }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  color='secondary'
                  inputProps={{ ...params.inputProps, maxLength: 30 }}
                  variant="standard"
                  placeholder="Add to collection"
                />
              )}
              options={allUserCollections}
              onChange={handleCollectionSelect}
            />
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}