import { lazy, Suspense, ComponentType } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import TripleDotLoadingProgress from "../components/tools/TripleDotLoadingProgress";
import { Box } from '@mui/material';
import Header from '../components/header/Header';
import Footer from '../components/Footer';

const ScholarMapsPage = lazy(() => import('../pages/ScholarMapsPage'));
const loadingIndicator = <TripleDotLoadingProgress sx={{ display: "block", margin: "auto", mt: 4 }} />;

const withLayout = (WrappedPage: ComponentType<{}>) => () =>
(
  <>
    <Box sx={{ minHeight: 'calc(100vh - 2em)' }}>
      <Header />
      <WrappedPage />
    </Box>
    <Footer />
  </>
);

const ScholarMapRouteSelector = () => {
  const location = useLocation();
  const { isAuthenticated, isLoading, redirectPath } = useAuth(location.pathname);

  if (isLoading) {
    return loadingIndicator;
  }

  if (redirectPath && redirectPath !== '/') {
    return <Navigate to={redirectPath} replace />;
  }

  return isAuthenticated ? (
    <Suspense fallback={loadingIndicator}>
      {withLayout(() => <ScholarMapsPage mode="login" />)()}
    </Suspense>
  ) : (
    <Suspense fallback={loadingIndicator}>
      <ScholarMapsPage mode="logout" />
      <Footer sx={{ position: "absolute", bottom: 0, width: "95vw", textAlign: "center" }} publicLegalPages={true} />
    </Suspense>
  );
};

export default ScholarMapRouteSelector;