import React from 'react';
import { Alert, AlertProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)({
  '& .MuiAlert-message': {
    fontSize: '1.25rem',
  },
  backgroundColor: '#6fbf73',
  border: '2px solid #357a38',
});

const CustomAlert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <StyledAlert ref={ref} {...props} />;
});

CustomAlert.displayName = "CustomAlert";

export default CustomAlert;
