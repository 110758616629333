import { AlertTitle, Box, Button, Collapse, List, ListItem, Modal, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetIterativeTrainingData, HideIterativeTrainingInstructions, RegisterIterativeTrainingVote } from '../../../services/RegistrationService';
import Alert from '@mui/material/Alert';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Paper, { PaperProps } from '../../../components/paper/Paper';
import { sanitizeColour, sanitizeRelevance } from '../../../utils/apiUtils';
import Grid from '@mui/material/Grid';
import BoxDefaultContent from '../../../components/box/BoxDefaultContent';
import BoxSection from '../../../components/box/BoxSection';
import BreakpointUtils from '../../../utils/BreakpointUtils';
import { RotateRight, ThumbUp } from '@mui/icons-material';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { common, grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { GetMaintenanceData } from "../../../services/AuthService";
import VotePreview from '../../../components/onboarding/VotePreview';
import { convertRelevance } from '../../../utils/relevanceUtils';
import TripleDotLoadingProgress from '../../../components/tools/TripleDotLoadingProgress';

interface Prediction {
  score: number,
  title: string,
  color: number[],
}

interface IterativeTrainingProps {
  stepperUpdate?: () => void;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

// normalise progress bar to fit 40 votes
const normalise = (value) => (value * 100) / 40;

export default function IterativeTrainig(props: IterativeTrainingProps) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const [loading, setLoading] = useState<boolean>(true);
  const [showInstructions, setShowInstructions] = useState<boolean>(true);
  const [paper, setPaper] = useState<PaperProps>();
  const [positiveVotes, setPositiveVotes] = useState<string[]>([]);
  const [negativeVotes, setNegativeVotes] = useState<string[]>([]);
  const [predictedPositives, setPredictedPositives] = useState<Prediction[]>([]);
  const [predictedNegatives, setPredictedNegatives] = useState<Prediction[]>([]);
  const [bootstrappedNumber, setBootstrappedNumber] = useState<number>(0);
  const [numberOfPositiveVotes, setNumberOfPositiveVotes] = useState<number>(0);
  const [numberOfNegativeVotes, setNumberOfNegativeVotes] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [insufficientVotesModalOpen, setInsufficientVotesModalOpen] = useState<boolean>(false);
  const [recomputingCache, setRecomputingCache] = useState<boolean>(true);
  const [iterative_training_report, setIterativeTrainingReport] = useState<any>([]);
  const navigate = useNavigate();

  const maintenanceStatus = GetMaintenanceData();
  maintenanceStatus.then(res => {
    // check if in maintenance mode
    if (res.is_maintenance_mode) {
      navigate({
        pathname: '/maintenance',
      });
    }
  });

  const handleDataUpdate = (response, iter) => {
    // guard vs response sometimes returning garbage with no paper_id or paper as undefined
    if (!response || !response.paper || !response.paper.paper_id) {
      console.log('got garbage, retrying..');
      if (iter < 3) {
        const req = GetIterativeTrainingData();
        req.then(res => handleDataUpdate(res, iter + 1));
      }
      return;
    };
    if (response.iterative_training_report != iterative_training_report){
      console.log(response.iterative_training_report);
      setIterativeTrainingReport(response.iterative_training_report);
    }
    const responsePaper: PaperProps = {
      paperId: response.paper.paper_id,
      authors: response.paper.shortened_authors,
      title: response.paper.title,
      paperLink: response.paper.url,
      mainText: response.paper.abstract,
      relevance: sanitizeRelevance(response.paper.score),
      is_cached: false,
      is_cached_filename: '',
      useScholarInboxPdfServer: false,
      arxivLink: response.paper.url,
    }
    setShowInstructions(!response.hide_instruction_bar)
    setPredictedPositives(response.positive_predictions);
    setPredictedNegatives(response.negative_predictions);
    setPaper(responsePaper);
    console.log('Predicted paper ID: ' + responsePaper.paperId);
    console.log('Predicted paper relevance: ' + responsePaper.relevance);
    setBootstrappedNumber(response.base_papers_n);
    setNumberOfPositiveVotes(response.pos_n);
    setNumberOfNegativeVotes(response.neg_n);
    setLoading(false);
  };

  useEffect(() => {
    // get initial data, force recreate cache
    const req = GetIterativeTrainingData(true);
    req.then(res => {
      handleDataUpdate(res, 0)
      setRecomputingCache(false);
    });
  }, []);

  const hideInstructionsClick = () => {
    const req = HideIterativeTrainingInstructions();
    req.then(res => {
      setShowInstructions(false);
    });
  };

  /* cast a vote
    like = 'true'
    skip = 'skip'
    dislike = 'false'
  */
  const handleVote = (vote: string) => {
    // protect from button mashing
    if (loading) return;
    // cast vote, set loading, get next paper
    const req = RegisterIterativeTrainingVote(paper.paperId, vote);
    if (vote === 'true') {
      positiveVotes.unshift(paper.title);
      setPositiveVotes(positiveVotes.slice(0, 10));
    }
    else if (vote === 'false') {
      negativeVotes.unshift(paper.title);
      setNegativeVotes(negativeVotes.slice(0, 10));
    }
    setPaper(undefined);
    setLoading(true);
    req.then(() => {
      const req = GetIterativeTrainingData();
      req.then(res => handleDataUpdate(res, 0));
    });
  };

  const handleFinishButtonClick = () => {
    const totalVotes = numberOfNegativeVotes + numberOfPositiveVotes;
    
    if (totalVotes < 10) {
      setInsufficientVotesModalOpen(true);
    } else if (totalVotes < 20) {
      setModalOpen(true);
    } else {
      props.stepperUpdate();
    }
  }

  const confirmContinue = () => {
    props.stepperUpdate();
  }

  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleInsufficientVotesModalClose = () => {
    setInsufficientVotesModalOpen(false);
  }

  return (
    <BoxDefaultContent>

      {showInstructions && (
        <BoxSection>
          <Collapse in={showInstructions}>
            <Alert
              severity="info"
              icon={false}
            >
              <AlertTitle>Iterative Training</AlertTitle>
              You are shown one paper title and abstract at a time and can vote if you like
              <img style={{ width: '1em', marginLeft: '3px', marginRight: '3px' }} src="/image/like.png" alt="Thumbs up" />
              or dislike
              <img style={{ width: '1em', marginLeft: '3px', marginRight: '3px' }} src="/image/dislike.png" alt="Thumbs down" />
              the paper or if you would like to skip
              <img style={{ width: '1em', marginLeft: '3px', marginRight: '3px' }} src="/image/skip.png" alt="Skip" />
              .
              <br />
              After 30-50 votes your personalised recommender is trained well enough, but you may continue voting to improve recommendations further.
            </Alert>
            <Button color="secondary" variant='contained' sx={{ float: 'right', backgroundColor: '#55b776', mt: 1 }} onClick={hideInstructionsClick}>Ok - I read this</Button>
          </Collapse>
        </BoxSection>
      )}

      {loading ? (
        <Box sx={{ my: '3rem', }}>
          {/* {recomputingCache && (<p>Please wait ~6 seconds for the model to train</p>)} */}
          <TripleDotLoadingProgress />
        </Box>
      ) : (
        <Box>
          <Box sx={{ my: '3rem', /*minHeight: '280px'*/ }}>
            <Paper {...{ ...paper, loading: loading, hideControls: true }} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 3,
            }}
          >
            <Box
              onClick={() => handleVote('true')}
              sx={{
                cursor: 'pointer',
                color: grey['500'],
                '@media(hover: hover)': {
                  '&:hover': {
                    color: common.black,
                  }
                },
              }}
            >
              <ThumbUp sx={{ fontSize: '7rem' }} />
            </Box>

            <Box
              onClick={() => handleVote('false')}
              sx={{
                color: grey['500'],
                cursor: 'pointer',
                mx: onMobile ? 1.5 : 3,
                '@media(hover: hover)': {
                  '&:hover': {
                    color: common.black,
                  }
                },
              }}
            >
              <ThumbDownIcon sx={{ fontSize: '7rem' }} />
            </Box>

            <Box
              onClick={() => handleVote('skip')}
              sx={{
                cursor: 'pointer',
                color: grey['500'],
                ml: -2,
                '@media(hover: hover)': {
                  '&:hover': {
                    color: common.black,
                  }
                },
              }}
            >
              <RotateRight sx={{ fontSize: '7rem', }} />
            </Box>
          </Box>
        </Box>
      )}

      <BoxSection>
        <Box sx={{ my: 1 }}>
          <Typography variant='subtitle1' >
            <b>Training Progress:</b>&nbsp;
            {numberOfPositiveVotes + numberOfNegativeVotes < 20 ? 'Please gather at least 20 votes to train your recommender. You can also cast votes later by voting on any paper you see or returning to this page.' :
              numberOfPositiveVotes + numberOfNegativeVotes < 40 ? 'You may finish the registration, but it is recommended to have at least 20 negative and 20 positive votes. You can also cast votes later by voting on any paper you see or returning to this page.'
                : 'Good number of votes collected. You may continue voting to further improve your recommender. You can also cast votes later by voting on any paper you see or returning to this page.'}
          </Typography>
          <LinearProgress variant="determinate"
            sx={{ height: '10px' }}
            color={numberOfPositiveVotes + numberOfNegativeVotes < 20 ? 'error' :
              numberOfPositiveVotes + numberOfNegativeVotes < 40 ? 'warning' : 'success'
            }
            value={normalise(Math.min(numberOfPositiveVotes + numberOfNegativeVotes, 40))}
          />
        </Box>

        { /* Info table */}
        <Table size='small' sx={{ mb: 3 }}>
          <TableBody>
            <TableRow
              key={'row0'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='right' sx={{ width: '50%' }}>Bootstrapped papers:</TableCell>
              <TableCell sx={{ width: '50%' }}>{bootstrappedNumber}</TableCell>
            </TableRow>
            <TableRow
              key={'row1'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">Positive votes:</TableCell>
              <TableCell>{numberOfPositiveVotes}</TableCell>
            </TableRow>
            <TableRow
              key={'row2'}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right">Negative votes:</TableCell>
              <TableCell>{numberOfNegativeVotes}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {(props.hasOwnProperty('stepperUpdate')) && (
          <Button
            color='secondary'
            variant='contained'
            sx={{ width: '100px', height: '40px', backgroundColor: '#CC4E49', mb: 4, mt: 1, }}
            title='Continue to the next step: Iterative Training'
            onClick={handleFinishButtonClick}
          >
            Finish
          </Button>
        )}

        {(positiveVotes.length > 0 || negativeVotes.length > 0) && (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ /*mt: 4*/ }}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' sx={{ textDecoration: 'underline' }}>
                Positive votes
              </Typography>
              <List dense={true}>
                {positiveVotes.map(vote => (
                  <ListItem
                    key={vote}
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      primary={vote}
                      sx={{ m: 0 }} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' sx={{ textDecoration: 'underline' }}>
                Negative votes
              </Typography>
              <List dense={true}>
                {negativeVotes.map(vote => (
                  <ListItem
                    key={vote}
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      primary={vote}
                      sx={{ m: 0 }} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        )}

        {(predictedPositives.length > 0 || predictedNegatives.length > 0) && (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ /*mt: 4*/ }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
                Predicted positives
              </Typography>
              <List dense={true}>
                {predictedPositives.map(prediction => (
                  <ListItem
                    key={prediction.title}
                    sx={{
                      p: 0,
                    }}
                  >
                    <VotePreview relevance={convertRelevance(prediction.score)} title={prediction.title} color={prediction.color} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
                Predicted negatives
              </Typography>
              <List dense={true}>
                {predictedNegatives.map(prediction => (
                  <ListItem
                    key={prediction.title}
                    sx={{
                      p: 0,
                    }}
                  >
                    <VotePreview relevance={convertRelevance(prediction.score)} title={prediction.title} color={prediction.color} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        )}

      </BoxSection>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Your recommender is not trained well enough yet!
          </Typography>
          <Typography variant="body1" >
            You have currently made less than 20 votes. Are you sure you want to finish registration? It is recommended to have at least 40 votes for recommender to be tuned well enough to fit your research preferences.
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button color='error' size='small' variant='outlined'
              sx={{ mr: '2px', mb: '2px' }}
              onClick={confirmContinue}
            >
              Continue anyway
            </Button>
            <Button color='secondary' size='small' variant='outlined'
              sx={{ ml: 1, mr: '2px', mb: '2px' }}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={insufficientVotesModalOpen}
        onClose={handleInsufficientVotesModalClose}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="error">
            Not enough votes to train your recommender
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            You need at least 10 votes to create a minimally effective recommender system. You currently have {numberOfPositiveVotes + numberOfNegativeVotes} votes.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Please continue voting on papers to help us understand your research interests better.
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button color='error' size='small' variant='contained'
              sx={{ mr: '2px', mb: '2px' }}
              onClick={handleInsufficientVotesModalClose}
            >
              Continue voting
            </Button>
          </Box>
        </Box>
      </Modal>

    </BoxDefaultContent>
  )
}
