import { useEffect, useState } from "react";
import { GetSessionData } from "../services/AuthService";

const adminOnlyEndpoints = [
  "/settings/onboarding",
  "/settings/conference-planner",
  "/settings/users",
  "/settings/statistics",
  "/settings/failed",
  "/settings/proceedings-uploader",
  "/settings/notify-users",
  "/settings/highlights-inference-settings",
  "/logging-view",
  "/settings/maintenance-control"
];

export function useAuth(currentPath: string) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    GetSessionData()
      .then(res => {
        if (!isMounted) return;

        if (!res || !res.is_logged_in) {
          setIsAuthenticated(false);
          setRedirectPath("/");
          return;
        }

        setIsAuthenticated(true);
        setIsAdmin(res.is_admin || false);

        // Determine the appropriate redirection path if user is mid onboarding
        switch (res.onboarding_status) {
          case "registered":
            setRedirectPath("/onboarding/registration?_s=1");
            break;
          case "bootstrapped":
            setRedirectPath("/onboarding/registration?_s=2");
            break;
          case "trained":
            setRedirectPath("/onboarding/registration?_s=3");
            break;
          case null:
            setRedirectPath("/logout");
            break;
        }

        // Redirect non-admin users from restricted pages
        if (!res.is_admin && adminOnlyEndpoints.includes(currentPath)) {
          setRedirectPath("/");
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsAuthenticated(false);
          setRedirectPath("/");
        }
      })
      .finally(() => {
        if (isMounted) setIsLoading(false);
      });

    return () => {
      isMounted = false; // Prevent state updates after unmount
    };
  }, [currentPath]);

  return { isAuthenticated, isAdmin, isLoading, redirectPath };
}
