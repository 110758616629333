import { Box, PaperProps } from "@mui/material";
import { useEffect, useState } from "react";
import { mockDigestData } from "../services/mockServiceData";
import responseSetPaperProps from "../utils/setPaperPropsUtils";
import { GetSimilarPapers } from "../services/DigestService";
import PaperPreview from "./PaperPreview";
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import CompactPaper from "./paper/CompactPaper";

export interface SimilarPapersProps {
  paperId: number;
  allUserCollections: string[];
}

export default function SimilarPapers(props: SimilarPapersProps) {
  const [loading, setLoading] = useState(true);
  const [similarPapers, setSimilarPapers] = useState<PaperProps[]>([]);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      setSimilarPapers(mockDigestData);
      return;
    }
    // get df data
    const digest: PaperProps[] = responseSetPaperProps(response);
    
    setSimilarPapers(digest);
  };

  useEffect(() => {
    const similarPapersData = GetSimilarPapers(props.paperId);
    similarPapersData.then(res => {
      handleDataFeedUpdate(res);
      setLoading(false);
    });
    setLoading(true);
  }, []);

  return (
    <Box sx={{ mt: -2 }}>
      {!loading && similarPapers.length > 0 && (
        similarPapers?.map(paper => (
          <CompactPaper {...paper} key={paper.title}
            all_user_collections={props.allUserCollections} />
        ))
      )}
      {loading && (
        <Box>
          <TripleDotLoadingProgress></TripleDotLoadingProgress>
        </Box>
      )}
    </Box>
  )
}