import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import TripleDotLoadingProgress from "../components/tools/TripleDotLoadingProgress";

const ProtectedRoute = () => {
  const location = useLocation();
  const { isAuthenticated, isLoading, redirectPath } = useAuth(location.pathname);

  if (isLoading) {
    return <TripleDotLoadingProgress sx={{ display: "block", margin: "auto", mt: 4 }} />;
  }

  if (redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
