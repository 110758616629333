import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper, { PaperProps } from '../components/paper/Paper';
import { GetAllUserCollections, GetBookmarkedData } from '../services/DigestService';
import { mockDigestData } from '../services/mockServiceData';
import FilterBarSortOrder from '../components/filterbar/FilterBarSortOrder';
import FilterBar from '../components/filterbar/FilterBar';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import FilterBarShowParam from '../components/filterbar/FilterBarShowParam';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import { useNavigate } from 'react-router-dom';
import CompactPaper from '../components/paper/CompactPaper';

function sortBookmarkedPapers(
  papers: PaperProps[],
  sortBy: string,
  sortOrder: string,
): PaperProps[] {
  let sortedPapers = [...papers];

  switch (sortBy) {
    case 'bookmark_date':
      sortedPapers = sortedPapers.filter((paper) => paper.bookmarked);
      sortedPapers.sort((a, b) => {
        if (!a.bookmarked && !b.bookmarked) return 0;
        if (!a.bookmarked) return 1;
        if (!b.bookmarked) return -1;
        return new Date(a.bookmarkDate || '') > new Date(b.bookmarkDate || '') ? 1 : -1;
      });
      break;
    case 'publication_date':
      sortedPapers.sort(
        (a, b) =>
          new Date(a.submittedDate || '') > new Date(b.submittedDate || '')
            ? 1
            : -1
      );
      break;
    case 'relevance':
      sortedPapers.sort((a, b) => (b.relevance || 0) - (a.relevance || 0));
      break;
    case 'title':
      sortedPapers.sort((a, b) => (a.title || '').localeCompare(b.title || ''));
      break;
    default:
      break;
  }

  if (sortOrder === 'desc' && sortBy !== 'relevance') {
    sortedPapers.reverse();
  }
  if (sortOrder === 'asc' && sortBy === 'relevance') {
    sortedPapers.reverse();
  }

  return sortedPapers;
}

export default function BookmarksPage() {

  const navigate = useNavigate();

  const [sortParam, setSortParam] = useState('bookmark_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [paging, setPaging] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [bookmarkedFeed, setBookmarkedFeed] = useState<PaperProps[]>([]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      setBookmarkedFeed(mockDigestData);
      return;
    }
    console.log(response);

    // build digest data
    const digest: PaperProps[] = responseSetPaperProps(response);
    setBookmarkedFeed(digest);
    setHasNextPage(response.has_next);
  };

  const removeBookmark = (paperId: number) => {
    const updatedBookmarkedFeed = bookmarkedFeed.filter(paper => {
      return paper.paperId !== paperId;
    });
    setBookmarkedFeed(updatedBookmarkedFeed);
  }

  const handleSortParamChange = (event: SelectChangeEvent) => {
    setSortParam(event.target.value);
    setPaging(1);
    const sortedPapers = sortBookmarkedPapers(bookmarkedFeed, event.target.value, sortOrder);
    setBookmarkedFeed(sortedPapers);
  }

  const handleSortOrderChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value);
    setPaging(1);
    const sortedPapers = sortBookmarkedPapers(bookmarkedFeed, sortParam, event.target.value);
    setBookmarkedFeed(sortedPapers);
  }

  const fetchNextPage = () => {
    console.log('fetching next');

    const trendingData = GetBookmarkedData(paging);
    trendingData.then(response => {
      if (response.success) {
        let digest = responseSetPaperProps(response);
        console.log(digest);
        setBookmarkedFeed(bookmarkedFeed.concat(digest));
        setHasNextPage(response.has_next);
        setPaging(paging + 1);
      } else {
        if (response.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
    console.log('done next');
  };


  // load initial digest data
  useEffect(() => {
    const relevanceData = GetBookmarkedData();
    const featureTogglesData = GetFeatureToggles();
    const allUserCollectionsRow = GetAllUserCollections();

    relevanceData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });

    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });

    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });
  }, []);
  

  return (
    <BoxDefaultContent fullWidth>
      <FilterBar>
        <FilterBarShowParam
          handleChange={handleSortParamChange}
          label='Sort option'
          optionList={[
            ['bookmark_date', 'Bookmark date'],
            ['publication_date', 'Publication date'],
            ['relevance', 'Relevance'],
            ['title', 'Title'],
          ]}
          value={sortParam}
        />
        <FilterBarSortOrder
          handleChange={handleSortOrderChange}
          label='Sort order'
          optionList={[
            ['desc', 'Descending'],
            ['asc', 'Ascending'],
          ]}
          value={sortOrder}
        />
      </FilterBar>
      <InfiniteScroll
        dataLength={bookmarkedFeed.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        style={{ overflow: 'visible' }}
        loader={
          <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
            <TripleDotLoadingProgress />
          </Box>
        }
      >
        {bookmarkedFeed.map(paper => (
          <CompactPaper {...paper}
            key={paper.title}
            deleteBookmarkedPaper={removeBookmark}
            featureToggles={featureToggles}
            all_user_collections={allUserCollections}
          />
        ))}
      </InfiniteScroll>

    </BoxDefaultContent>
  )
}